
export interface IOperator {
    username:string
    company: string
    email: string
    password: string
}

export const operatorDetailsInitValues: IOperator = {
    username:'',
    company: '',
    email: '',
    password: '',
}
