/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'

import {FormikConfig, useFormik} from "formik";
import {IOperator ,operatorDetailsInitValues as initialValues} from "./OperatorModel";
import {baseRoute} from "../../endpoints/endpoints";
import {useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";

const UpdateOperatorPage: FC = () => {
    const [data, setData] = useState<IOperator>(initialValues)
    const updateData = (fieldsToUpdate: Partial<IOperator>): any => {
        const updatedData = Object.assign(data, fieldsToUpdate)

        setData(updatedData)

        return updatedData
    }
    const url= window.location.href;
    let paramString = url.split('?')[1];
    let queryString = new URLSearchParams(paramString);
    const user = queryString.get('uid')
    const config = {
        header: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };
    const [editing,setEditing] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);

    const [{ data: putData, loading: putLoading, error: putError },ViewOperator] = baseRoute({
        url: '/a/getOperatorById',
        method: 'post',
        headers: config.header,
        data:{u_id:user}
    }, { manual: false });

    useEffect(() => {
        ViewOperator()
    }, []);

    const [{ data: updatedData, loading: updateLoading, error: updateError },updateOperatorDB] = baseRoute({
        url: '/a/update_operator',
        method: 'post',
        headers: config.header,
    }, { manual: true });

    const [{data:deleteData ,loading: deleteLoading,error: deleteError}, deleteOperatorDB] = baseRoute({
        url: '/a/delete_operator',
        method: 'post',
        headers: config.header,
        data:{u_id:user}
    }, { manual: true });

    let data_up:IOperator;
    if(putData !== undefined)
    {
        data_up = putData;
        initialValues.username = data_up.username;
        initialValues.company = data_up.company;
        initialValues.email = data_up.email;
        initialValues.password = data_up.password;
    }
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const formik = useFormik<IOperator>({
        initialValues,
        onSubmit: (values) => {
            setLoading(true)
            console.log(values)
            setTimeout(() => {
                let updatedData = updateData(values);
                updatedData.u_id = user;
                updateOperatorDB({data: updatedData}).then(r =>{console.log(r); navigate('/listOperator')});
                setLoading(false);
            }, 1000)
        },
    })

    return(<>
        <PageTitle breadcrumbs={[]}>{editing ? "Update Operator" : "View Operator" }</PageTitle>
        {/* begin::Row */}
        <div className="modal fade" tabIndex={-1} id="delete_modal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Confirm Delete</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className={'p-3'}>
                        <p className={'fs-5'}>Are You Sure You Want To Delete The Operator ?</p>
                        </div>
                        <div className={'p-3'}>
                            <p className={'fs-5 fw-bolder text-danger'}>
                                TYPE "DELETE" IN THE BOX BELOW TO CONFIRM
                            </p>
                            <input className={'form-control'} onChange={(e)=>{
                                if(e.target.value==='DELETE') {
                                    setDeleteModal(true)
                                }
                                else{
                                    setDeleteModal(false)
                                }
                            }} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            data-bs-dismiss="modal"
                        >
                            Cancel
                        </button>
                        <button type="button" className="btn btn-danger" disabled={!deleteModal} data-bs-dismiss="modal"  onClick={()=>{deleteOperatorDB().then(()=>navigate('/listOperator'))}}>
                            Confirm Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>


        <div className='container-fluid'>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                {/* begin::Col */}
                <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
                    <div className='card mb-5 mb-xl-10'>
                        <div
                            className='card-header border-0'
                        >
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Profile Details</h3>
                            </div>
                            <div className="card-toolbar">
                                <div className={'px-3'}>
                                <Button
                                    className='btn btn-danger btn-small'
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete_modal"
                                >
                                    Delete
                                </Button>
                                </div>
                                <div className={'px-3'}>
                                {!editing ? <Button
                                    className='btn btn-primary btn-small'
                                    onClick={() => {
                                        setEditing(true)
                                    }}>
                                    Edit
                                </Button>:<Button
                                    className='btn btn-primary btn-small'
                                    onClick={() => {
                                        setEditing(false)
                                    }}>
                                    Cancel Editing
                                </Button> }
                                </div>
                            </div>
                        </div>

                        {putData && <div id='kt_account_profile_details' className='collapse show'>
                            <form onSubmit={formik.handleSubmit} noValidate className='form'>
                                <div className='card-body border-top p-9'>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>User
                                            Name</label>
                                        <div className='col-lg-8'>
                                            <div className='row'>
                                                <div className='col-lg-6 fv-row'>
                                                    <input
                                                        disabled={!editing}
                                                        type='text'
                                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                        placeholder='First name'
                                                        // defaultValue={data_up.username}
                                                        id={'username'}
                                                        name={'username'}
                                                         value={formik.values.username}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {formik.touched.username && formik.errors.username && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.username}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Company</label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                disabled={!editing}
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Company name'
                                                // defaultValue={data_up.company}
                                                id={'company'}
                                                name={'company'}
                                                 value={formik.values.company}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.company && formik.errors.company && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.company}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                disabled={!editing}
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Email'
                                                // defaultValue={data_up.email}
                                                id={'email'}
                                                name={'email'}
                                                 value={formik.values.email}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.email && formik.errors.email && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.email}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label
                                            className='col-lg-4 col-form-label required fw-bold fs-6'>Password</label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                disabled={!editing}
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Password'
                                                // defaultValue={data_up.password}
                                                id={'password'}
                                                name={'password'}
                                                value={formik.values.password}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.password && formik.errors.password && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.password}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                                {editing &&
                                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                        {!loading && 'Save Changes'}
                                        {loading && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                Please wait...{' '}
                                                <span
                                                    className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                                }
                            </form>
                        </div>}
                    </div>
                </div>
                {/* end::Col */}

            </div>
        {/* end::Row */}
        </div>
    </>)
}


export {UpdateOperatorPage}
