/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC} from 'react'
import {KTIcon} from '../../../../../_metronic/helpers'
import {ErrorMessage, Field} from 'formik'

const Step1: FC = () => {
  return (
    <div className='w-100'>
      <div className='pb-10 pb-lg-15'>
        <h2 className='fw-bolder d-flex align-items-center text-dark'>
          Enter User Details
          <i
            className='fas fa-exclamation-circle ms-2 fs-7'
            data-bs-toggle='tooltip'
          ></i>
        </h2>

        <div className='text-gray-400 fw-bold fs-6'>
          If you need more info, please check out
          <a href='/dashboard' className='link-primary fw-bolder'>
            {' '}
            Help Page
          </a>
          .
        </div>
      </div>

      <div className='fv-row'>
        <div className='row'>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Unique ID</label>

            <div className='col-lg-8 fv-row'>
              <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Unique ID'
                  id={'c_unique_id'}
              />

            </div>
          </div>
          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

            <div className='col-lg-8 fv-row'>
              <input
                  type='text'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Email'
                  id={'u_email'}
              />

            </div>
          </div>

          <div className='row mb-6'>
            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Password</label>

            <div className='col-lg-8 fv-row'>
              <input

                  type='password'
                  className='form-control form-control-lg form-control-solid'
                  placeholder='Password'
                    id={'u_password'}
              />

            </div>
          </div>
          <div className='text-danger mt-2'>
            <ErrorMessage name='accountType' />
          </div>
        </div>
      </div>
    </div>
  )
}

export {Step1}
