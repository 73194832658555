/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'

import {useFormik} from "formik";
import {baseRoute} from "../../endpoints/endpoints";
import {useNavigate, useParams} from "react-router-dom";
import {IReview , reviewInitialValues as initialValues} from "./ReviewModel";

const AddReview: FC = () => {
    const [data, setData] = useState<IReview>(initialValues)
    const updateData = (fieldsToUpdate: Partial<IReview>): any => {
        const updatedData = Object.assign(data, fieldsToUpdate)
        setData(updatedData)
        return updatedData
    }
    const navigate = useNavigate();
    const {u_id} = useParams();
    console.log(u_id)
    const config = {
        header: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
    };

    const [{ data: putData, loading: putLoading, error: putError },addUserDB] = baseRoute({
        url: '/a/createReview',
        method: 'post',
        headers: config.header,
    }, { manual: true });

    const [loading, setLoading] = useState(false)
    const formik = useFormik<IReview>({
        initialValues,
        onSubmit: (values) => {
            setLoading(true)
            values.user=u_id;
            setTimeout(() => {
                const updatedData = updateData(values)
                console.log(updatedData)
                addUserDB({
                    data: data
                }).then(r => {
                    console.log(putData);
                    navigate('/listUser?review=added');
                }).catch(e =>{
                    console.log(e);
                    alert("Something went wrong");
                })
                setLoading(false)
            }, 1000)
        },
    })

    return(<>
        <PageTitle breadcrumbs={[]}>Add Review For User</PageTitle>
        {/* begin::Row */}
        <div className='container-fluid'>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                {/* begin::Col */}
                <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
                    <div className='card mb-5 mb-xl-10'>
                        <div
                            className='card-header border-0 cursor-pointer'
                            role='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#kt_account_profile_details'
                            aria-expanded='true'
                            aria-controls='kt_account_profile_details'
                        >
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Review Details</h3>
                            </div>
                        </div>

                        <div id='kt_account_profile_details' className='collapse show'>
                            <form onSubmit={formik.handleSubmit} noValidate className='form'>
                                <div className='card-body border-top p-9'>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Review Content</label>

                                        <div className='col-lg-8 fv-row'>

                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Review Content'
                                                {...formik.getFieldProps('review_content')}
                                            />
                                            {formik.touched.review_content && formik.errors.review_content && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.review_content}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body border-top p-9'>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Review Rating</label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Review Rating'
                                                {...formik.getFieldProps('review_rating')}
                                            />
                                            {formik.touched.review_rating && formik.errors.review_rating && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.review_rating}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body border-top p-9'>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Source</label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Source'
                                                {...formik.getFieldProps('source')}
                                            />
                                            {formik.touched.source && formik.errors.source && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.source}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body border-top p-9'>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>OverAll Sentiment</label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='OverAll Sentiment'
                                                {...formik.getFieldProps('overall_sentiment')}
                                            />
                                            {formik.touched.overall_sentiment && formik.errors.overall_sentiment && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.overall_sentiment}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                        {!loading && 'Save Changes'}
                                        {loading && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* end::Col */}

            </div>
            {/* end::Row */}
        </div>
    </>)
}


export {AddReview}
