/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {ISUser,shopifyUserDetailsInitValues as initialValues} from '../core/ShopifyModel'
import {baseRoute} from "../../../endpoints/endpoints";
import StepWizard from "react-step-wizard";
import useAxios from "axios-hooks";
import GooglePlacesAutocomplete from "react-google-places-autocomplete";
import {hooks} from "prismjs";
import {useQrDecode} from "react-qr-hooks";
import add = hooks.add;
export function Registration_Shopify() {
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()
    const [isFirst,setIsFirst] = useState<boolean>(true)
    const [isLast,setIsLast] = useState<boolean>(false)
    const [data, setData] = useState<ISUser>(initialValues)
    const [isGoogle , setIsGoogle] = useState<boolean>(true)
    const [address , setAddress] = useState("");
    const [menu, setMenu] = useState("");
    const [file,setFile] = useState<any>();
    let { unique_id } = useParams();
    console.log(unique_id);
    let add;
    let isFB = false;
    if(unique_id.startsWith('FB')){
        isFB = true;
    }
    const updateData = (fieldsToUpdate: Partial<ISUser>): any => {
        const updatedData = Object.assign(data, fieldsToUpdate)
        setData(updatedData)
        return updatedData
    }
    let decode;
    function HandleFileChange(file: any){
        console.log('test')
        console.log(file);
        decode = useQrDecode(file[0])
        console.log(decode);
    }


    const config = {
        header: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
    };
    let firstName ;
    let lastName ;
    let email ;
    let phone ;
    let card ;
    let address_form='address';
    let website = 'Not Available';
    let u_menu_link = 'Not Available';
    let u_place_id;

    const navigate = useNavigate();

    const formik = useFormik({
        initialValues,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                values.u_unique_id = unique_id;
                const updatedData = updateData(values)
                console.log(updatedData)
                firstName = updatedData.u_fname;
                lastName = updatedData.u_lname;
                email = updatedData.u_email;
                phone = updatedData.u_phone;
                card = updatedData.u_unique_id;
                // address_form = updatedData.u_address;
                website = updatedData.u_website;
                u_menu_link = updatedData.u_menu_link;
                u_place_id = place_id;
                if(isFB){
                window.location.replace('https://7e3ea4.myshopify.com/cart/clear?return_to=/cart/add?items[][id]=45309199024419%26items[][quantity]=1%26items[][selling_plan]=4804182307%26items[]properties[card_id]='+card+'%26items[]properties[firstName]='+firstName+'%26items[]properties[lastName]='+lastName+'%26items[]properties[email]='+email+'%26items[]properties[phone]='+phone+'%26items[]properties[address]='+address_form+'%26items[]properties[place_id]='+u_place_id+'%26items[]properties[menu_link]='+u_menu_link+'%26items[]properties[website]='+website+'%26return_to=/checkout')
                }
                else{
                    window.location.replace('https://7e3ea4.myshopify.com/cart/clear?return_to=/cart/add?items[][id]=45504506855715%26items[][quantity]=1%26items[][selling_plan]=4856578339%26items[]properties[card_id]='+card+'%26items[]properties[firstName]='+firstName+'%26items[]properties[lastName]='+lastName+'%26items[]properties[email]='+email+'%26items[]properties[phone]='+phone+'%26items[]properties[address]='+address_form+'%26items[]properties[place_id]='+u_place_id+'%26items[]properties[menu_link]='+u_menu_link+'%26items[]properties[website]='+website+'%26return_to=/checkout')
                }
                setLoading(false)
            } catch (error) {
                console.error(error)
                saveAuth(undefined)
                setStatus('The Registration_Shopify details is incorrect')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])
    const [state, updateState] = useState({
        form: {},
        SW: null,
        demo: true, // uncomment to see more
    });

    const updateForm = (key, value) => {
        const { form } = state;
        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    const onStepChange = (stats) => {
        console.log('Current step:', stats);
        console.log('State:', SW);
        stats.isFirstStep= isFirst;
        if(SW.state.activeStep !== 0){
            setIsFirst(false);
            stats.isFirstStep = false;
        }
        if(SW.state.activeStep === 3){
            console.log('Last Step');
            console.log('Last Step',formik.values);
            setIsLast(true);
        }else {
            setIsLast(false);
        }
        console.log('Is it first step?',isFirst);
    };
    let place_id;
    const setInstance = (SW: any) => updateState({
        ...state,
        SW,
    });

    const { SW, demo } = state;
    useEffect(() => {
        if(address!==""){
            //@ts-ignore
            place_id = address.value.place_id
            formik.values.u_place_id = place_id;

            //@ts-ignore
            add = address.label
            formik.values.u_address = add;
            console.log(place_id);
            // (document.getElementById('u_place_id') as HTMLInputElement).value = place_id;
            // (document.getElementById('u_address') as HTMLInputElement).value = add;
            }
    }, [address]);

    return (
            <div id='' className='card shadow-lg'>

                <div className={'d-flex justify-content-center align-item-center card-body bg-white'}>
                    <div>
                    <div className={''}>
                        <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/logos/Logo_5.avif')}
                        className='h-100px my-5'
                        />
                    </div>
                    <div className='text-center mb-11'>
                        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
                    </div>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>

                    <div className='card-body border-top p-9'>
                        <StepWizard
                            isHashEnabled={true}
                            initialStep={0}
                            onStepChange={onStepChange}
                            instance={setInstance}
                        >
                        <div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Card ID</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Unique ID'
                                    disabled={true}

                                    {...formik.getFieldProps('u_unique_id')}
                                    value={unique_id}                                />
                                {formik.touched.u_unique_id && formik.errors.u_unique_id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.u_unique_id}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>
                            <div className='col-lg-8'>
                                <div className='row'>
                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='First name'
                                            {...formik.getFieldProps('u_fname')}
                                        />
                                        {formik.touched.u_fname && formik.errors.u_fname && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_fname}</div>
                                            </div>
                                        )}
                                    </div>

                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Last name'
                                            {...formik.getFieldProps('u_lname')}
                                        />
                                        {formik.touched.u_lname && formik.errors.u_lname && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_lname}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        </div>

                        <div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Phone</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Phone'
                                    {...formik.getFieldProps('u_phone')}
                                />
                                {formik.touched.u_phone && formik.errors.u_phone && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.u_phone}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Email'
                                        {...formik.getFieldProps('u_email')}
                                    />
                                    {formik.touched.u_email && formik.errors.u_email && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.u_email}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Company / Store Name</label>

                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Company name'
                                        {...formik.getFieldProps('u_company_name')}
                                    />
                                    {formik.touched.u_company_name && formik.errors.u_company_name && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.u_company_name}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div>
                            <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Address</label>
                                <div>
                                    <GooglePlacesAutocomplete
                                        selectProps={{
                                            address: address,
                                            //@ts-ignore
                                            onChange: setAddress,
                                            placeholder: 'Search Your Business',
                                        }}
                                    />
                                    <a href={'https://business.google.com/create'} target={'_blank'}> Cant Find Your Business Click Here.</a>
                                    {/*<div>*/}
                                    {/*    Add modal to popup if business cant be found*/}
                                    {/*</div>*/}
                                </div>
                            </div>
                        </div>
                        <div>
                        <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label  fw-bold fs-6'>Website  <span className={'text-muted text-small'}>(Optional)</span></label>

                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Website'
                                        {...formik.getFieldProps('u_website')}
                                    />
                                    {formik.touched.u_website && formik.errors.u_website && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.u_website}</div>
                                        </div>
                                    )}
                                </div>
                            </div>

                            {isFB &&
                                <div>
                                    {/*<div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>Place Id</span>
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Place Id'
                                                name={'u_place_id'}
                                                id={'u_place_id'}
                                                disabled={true}
                                            />
                                            {formik.touched.u_place_id && formik.errors.u_place_id && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.u_place_id}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>*/}
                                    {/*// TODO add a select or checkbox to select if menu link is available*/}
                                    <div className={'row'}>
                                        <label className='col-6 col-form-label fw-bold fs-6'>
                                            <span className=''>Menu Link Available<span className={'text-muted text-small'}>(Optional)</span></span>
                                        </label>
                                        <select className={'form-control form-control-sm form-control-solid col-6 w-25 m-5'} onChange={(e)=>{
                                            if(e.target.value==='true') {
                                                setIsGoogle(true);
                                            }
                                        }}>
                                            <option value={'true'}>Yes</option>
                                            <option value={'false'}>No</option>
                                        </select>
                                    </div>
                             <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className=''>Menu Link <span className={'text-muted text-small'}>(Optional)</span></span>

                            </label>

                             <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Menu Link'
                                    {...formik.getFieldProps('u_menu_link')}
                                />
                                {formik.touched.u_menu_link && formik.errors.u_menu_link && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.u_menu_link}</div>
                                    </div>
                                )}
                            </div>

                        </div>
                                    {/*<div  className='row mb-6'>*/}
                                    {/*    <label className='col-lg-4 col-form-label fw-bold fs-6'>*/}
                                    {/*        <span className=''>Menu QR <span className={'text-muted text-small'}>(Optional)</span></span>*/}

                                    {/*    </label>*/}
                                    {/*    <input type={'file'} accept={'image/*'}  className={'form-control mt-3'} onChange={(e)=>{setFile(e.target.files);decode = useQrDecode(e.target.files[0])*/}
                                    {/*        console.log(decode);}}/>*/}
                                    {/*</div>*/}

                                </div>
                            }
                        </div>


                        </StepWizard>
                    </div>
                    {SW &&
                        <div className='card-footer d-flex justify-content-around py-6 px-9'>
                            {!isFirst && <button type={'button'} className={'btn btn-secondary'} onClick={SW.previousStep}>Previous
                                Step
                            </button>}

                            {!isLast &&
                                <button type={'button'} className={'btn btn-secondary'} onClick={SW.nextStep}>Next
                                Step
                                </button>
                            }
                            {isLast &&
                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                    {!loading && 'Save Changes'}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                    )}
                                </button>
                            }
                    </div>
                    }

                </form>
            </div>


    )
}
