/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect, useRef} from 'react'
import {Form, Formik, FormikValues, useFormik} from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import {getUserByToken, register} from '../core/_requests'
import {Link, useNavigate} from 'react-router-dom'
import {KTIcon, toAbsoluteUrl} from '../../../../_metronic/helpers'
import {StepperComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {IUser,userDetailsInitValues as initialValues} from '../core/UserModel'
import {baseRoute} from "../../../endpoints/endpoints";
import {Step1} from "./steps/Step1";
import {Step2} from "./steps/Step2";
import {Step3} from "./steps/Step3";
import {Step4} from "./steps/Step4";
import {Step5} from "./steps/Step5";

/*const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('First name is required'),
  u_email: Yup.string()
    .u_email('Wrong u_email format')
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Email is required'),
  lastname: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Last name is required'),
  u_password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password is required'),
  changeu_password: Yup.string()
    .min(3, 'Minimum 3 symbols')
    .max(50, 'Maximum 50 symbols')
    .required('Password confirmation is required')
    .oneOf([Yup.ref('u_password')], "Password and Confirm Password didn't match"),
  acceptTerms: Yup.bool().required('You must accept the terms and conditions'),
})*/

export function Registration1() {
  const [loading, setLoading] = useState(false)
  const {saveAuth, setCurrentUser} = useAuth()
  const [data, setData] = useState<IUser>(initialValues)
  const [isGoogle , setIsGoogle] = useState<boolean>(true)

  const stepperRef = useRef<HTMLDivElement | null>(null)
  const stepper = useRef<StepperComponent | null>(null)
  const [isSubmitButton, setSubmitButton] = useState(false)





  const updateData = (fieldsToUpdate: Partial<IUser>): any => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData(updatedData)
    return updatedData

  }
  const config = {
    header: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      // authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };
  const [{ data: putData, loading: putLoading, error: putError },addUserDB] = baseRoute({
    url: '/user/register_user',
    method: 'post',
    headers: config.header,
  }, { manual: true });
 const navigate = useNavigate();
  const formik = useFormik({
    initialValues,
    onSubmit: async (values, {setStatus, setSubmitting}) => {
      setLoading(true)
      try {
        const updatedData = updateData(values)
        console.log(updatedData)
        addUserDB({
          data: data
        }).then(r => {
          console.log(putData);
          navigate('/dashboard?user=added');
        }).catch(e =>{
          console.log(e);
          alert("Something went wrong");
        })
        setLoading(false)
      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  const loadStepper = () => {
    stepper.current = StepperComponent.createInsance(stepperRef.current as HTMLDivElement)
  }

  const prevStep = () => {
    if (!stepper.current) {
      return
    }
    stepper.current.goPrev()
    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)
  }

  const submitStep = (values: IUser, actions: FormikValues) => {

    if (!stepper.current) {
      return
    }
    if (stepper.current.currentStepIndex !== stepper.current.totalStepsNumber) {
      stepper.current.goNext()
    } else {
      stepper.current.goto(1)
      actions.resetForm()
    }
    setSubmitButton(stepper.current.currentStepIndex === stepper.current.totalStepsNumber)

  }


  useEffect(() => {
    if (!stepperRef.current) {
      return
    }

    loadStepper()
  }, [stepperRef])
  return (
    <form
      className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
      noValidate
      id='kt_login_signup_form'
      onSubmit={formik.handleSubmit}
    >
      {/* begin::Heading */}
      <div className='text-center mb-11'>
        {/* begin::Title */}
        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
        {/* end::Title */}


      </div>
      {/* end::Heading */}
      <div
          ref={stepperRef}
          className='stepper stepper-links d-flex flex-column pt-15'
          id='kt_create_account_stepper'
      >
        <div className='stepper-nav mb-5'>
          <div className='stepper-item current' data-kt-stepper-element='nav'>
            <h3 className='stepper-title'>Account Info</h3>
          </div>

          <div className='stepper-item' data-kt-stepper-element='nav'>
            <h3 className='stepper-title'>Business Info</h3>
          </div>

          <div className='stepper-item' data-kt-stepper-element='nav'>
            <h3 className='stepper-title'>Links</h3>
          </div>

          <div className='stepper-item' data-kt-stepper-element='nav'>
            <h3 className='stepper-title'>Completed</h3>
          </div>
        </div>






      {/* begin::Form group Firstname */}
      <Formik initialValues={initialValues} onSubmit={submitStep}>
        {() => (
            <Form className='mx-auto mw-600px w-100 pt-15 pb-10' id='kt_create_account_form'>
              <div className='current' data-kt-stepper-element='content'>
                <Step1 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step2 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step3 />
              </div>

              <div data-kt-stepper-element='content'>
                <Step5 />
              </div>

              <div className='d-flex flex-stack pt-15'>
                <div className='mr-2'>
                  <button
                      onClick={prevStep}
                      type='button'
                      className='btn btn-lg btn-light-primary me-3'
                      data-kt-stepper-action='previous'
                  >
                    <KTIcon iconName='arrow-left' className='fs-4 me-1' />
                    Back
                  </button>
                </div>

                <div>
                  <button type='submit' className='btn btn-lg btn-primary me-3'>
                      <span className='indicator-label'>
                        {!isSubmitButton && 'Continue'}
                        {isSubmitButton && 'Submit'}
                        <KTIcon iconName='arrow-right' className='fs-3 ms-2 me-0' />
                      </span>
                  </button>
                </div>
              </div>
            </Form>
        )}
      </Formik>
      {/* end::Form group */}
      </div>
    </form>

  )
}
