
export interface ISUser {
    u_unique_id:string
    u_fname: string
    u_lname: string
    u_company_name: string
    u_address: string
    u_website: string
    u_phone: string
    u_email: string
    u_menu_link: string
    u_place_id: string
}

export const shopifyUserDetailsInitValues: ISUser = {
    u_unique_id:'',
    u_fname: '',
    u_phone: '',
    u_lname: '',
    u_company_name: '',
    u_address: '',
    u_website: '',
    u_email: '',
    u_menu_link: '',
    u_place_id: '',
}
