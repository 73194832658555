import {Route, Routes} from 'react-router-dom'
import {Registration} from './components/Registration'
// import {ForgotPassword} from './components/ForgotPassword'
import {Login} from './components/Login'
import {AuthLayout} from './AuthLayout'
import {Registration1} from "./components/Registration1";
import {Registration_Shopify} from "./components/Registration_Shopify";
import {NotActivated} from "./components/NotActivated";

const AuthPage = () => (
  <Routes>
    <Route element={<AuthLayout />}>
      <Route path='login' element={<Login />} />
      <Route path='registration/:unique_id' element={<Registration />} />
      <Route path='registration1/:unique_id' element={<Registration1 />} />
      <Route path='registrationShopify/:unique_id' element={<Registration_Shopify />} />
      <Route path='NotActivated' element={<NotActivated />} />
      <Route index element={<Login />} />
    </Route>
  </Routes>
)

export {AuthPage}
