
export interface IReview {
    review_content:string,
    review_rating:string,
    source:string,
    overall_sentiment:string,
    user:string,
}

export const reviewInitialValues: IReview = {
    user:'',
    overall_sentiment:'',
    review_content:'',
    review_rating:'',
    source:'',
}
