import {FC} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useFormik} from 'formik'

const ReviewForm: FC = () => {
  const formik = useFormik({
    initialValues: {
      nps: '',
      customerSentiments: {
        topPositive: ['', '', ''],
        topNegative: ['', '', ''],
      },
      impactOnBusiness: '',
      closestCompetitor: '',
      closestCompetitorRating: '',
      recommendation: '',
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
    validate: (values) => {
      const errors: any = {}

      // Validate NPS
      if (!values.nps) {
        errors.nps = 'NPS is required'
      }

      if (
        !values.customerSentiments?.topPositive.every(
          (sentiment) => sentiment.trim() && sentiment.length <= 100
        )
      ) {
        errors.customerSentiments = {
          ...errors.customerSentiments,
          topPositive: 'All top positive sentiments must be 100 characters or less',
        }
      }

      if (
        !values.customerSentiments?.topNegative.every(
          (sentiment) => sentiment.trim() && sentiment.length <= 100
        )
      ) {
        errors.customerSentiments = {
          ...errors.customerSentiments,
          topNegative: 'All top negative sentiments must be 100 characters or less',
        }
      }

      // Validate Impact on Business
      if (!values.impactOnBusiness) {
        errors.impactOnBusiness = 'Impact on business is required'
      }

      // Validate Closest Competitor
      if (!values.closestCompetitor) {
        errors.closestCompetitor = 'Closest competitor is required'
      }

      // Validate Closest Competitor Rating
      if (!values.closestCompetitorRating) {
        errors.closestCompetitorRating = 'Closest competitor rating is required'
      }

      if (!values.recommendation) {
        errors.recommendation = 'Recommendation is required'
      } else if (values.recommendation.length > 250) {
        errors.recommendation = 'Impact on business must be 250 characters or less'
      }

      return errors
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>Review Form</PageTitle>
      <div className='container-fluid'>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
            <div className='card mb-5 mb-xl-10'>
              <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
              >
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Review</h3>
                </div>
              </div>
              <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>
                      Net promoter score
                    </label>

                    <div className='col-lg-8 fv-row'>
                      <input
                        type='number'
                        min={'0'}
                        max={'100'}
                        className='form-control form-control-lg form-control-solid'
                        placeholder='Net promoter score'
                        {...formik.getFieldProps('nps')}
                      />
                      {formik.touched.nps && formik.errors.nps && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.nps}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <h6>Customer Sentiments</h6>
                    <div className='col-md-6'>
                      <div className='d-flex flex-column gap-2'>
                        <label htmlFor='form-label' className='required'>
                          Top Positive
                        </label>
                        {formik.values.customerSentiments.topPositive.map((value, index) => (
                          <input
                            key={index}
                            type='text'
                            className='form-control form-control-solid'
                            value={value}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `customerSentiments.topPositive[${index}]`,
                                e.target.value
                              )
                            }
                          />
                        ))}
                        {formik.errors.customerSentiments &&
                          formik.errors.customerSentiments.topPositive && (
                            <div className='text-danger'>
                              {formik.errors.customerSentiments.topPositive}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className='col-md-6'>
                      <div className='d-flex flex-column gap-2'>
                        <label htmlFor='form-label' className='required'>
                          Top Negative
                        </label>
                        {formik.values.customerSentiments.topNegative.map((value, index) => (
                          <input
                            key={index}
                            type='text'
                            className='form-control form-control-solid'
                            value={value}
                            onChange={(e) =>
                              formik.setFieldValue(
                                `customerSentiments.topNegative[${index}]`,
                                e.target.value
                              )
                            }
                          />
                        ))}
                        {formik.errors.customerSentiments &&
                          formik.errors.customerSentiments.topNegative && (
                            <div className='text-danger'>
                              {formik.errors.customerSentiments.topNegative}
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label htmlFor='' className='col-lg-4 col-form-label required'>
                      Impact on business
                    </label>
                    <div className='col-lg-8'>
                      <select
                        name='impactOnBusiness'
                        id='impactOnBusiness'
                        className='form-select'
                        {...formik.getFieldProps('impactOnBusiness')}
                      >
                        <option value=''>Select</option>
                        <option value='low'>Low Impact</option>
                        <option value='med'>Medium Impact</option>
                        <option value='high'>High Impact</option>
                      </select>
                      {formik.touched.impactOnBusiness && formik.errors.impactOnBusiness && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.impactOnBusiness}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label htmlFor='' className='col-lg-4 col-form-label required'>
                      Closest competitor
                    </label>
                    <div className='col-lg-8'>
                      <input
                        type='text'
                        className='form-control form-control-solid'
                        placeholder='Closest competitor'
                        {...formik.getFieldProps('closestCompetitor')}
                      />
                      {formik.touched.closestCompetitor && formik.errors.closestCompetitor && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.closestCompetitor}</div>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className='row mb-6'>
                    <label htmlFor='' className='col-lg-4 col-form-label required'>
                      Closest competitor rating
                    </label>
                    <div className='col-lg-8'>
                      <input
                        type='number'
                        min={'0'}
                        max={'5'}
                        className='form-control form-control-solid'
                        placeholder='Closest competitor rating'
                        {...formik.getFieldProps('closestCompetitorRating')}
                      />
                      {formik.touched.closestCompetitorRating &&
                        formik.errors.closestCompetitorRating && (
                          <div className='fv-plugins-message-container'>
                            <div className='fv-help-block'>
                              {formik.errors.closestCompetitorRating}
                            </div>
                          </div>
                        )}
                    </div>
                  </div>

                  <div className='row mb-6'>
                    <label htmlFor='' className='col-lg-4 col-form-label required'>
                      Recommendation
                    </label>
                    <div className='col-lg-8'>
                      <textarea
                        className='form-control form-control-solid'
                        placeholder='Recommendation'
                        maxLength={250}
                        {...formik.getFieldProps('recommendation')}
                      />
                      {formik.touched.recommendation && formik.errors.recommendation && (
                        <div className='fv-plugins-message-container'>
                          <div className='fv-help-block'>{formik.errors.recommendation}</div>
                        </div>
                      )}
                    </div>
                  </div>

                  <div className='d-flex justify-content-end'>
                    <button type='submit' className='btn btn-primary me-2'>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
export {ReviewForm}
