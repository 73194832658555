
export interface IPartner {
    username:string
    company: string
    email: string
    password: string
}

export const partnerDetailsInitValues: IPartner = {
    username:'',
    company: '',
    email: '',
    password: '',
}
