/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {KTIcon} from '../../../../helpers'
import {SidebarMenuItemWithSub} from './SidebarMenuItemWithSub'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {currentUser} = useAuth()
  console.log(currentUser)

  return (
    <>
      <SidebarMenuItem
        to='/dashboard'
        icon='element-11'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      />
      {/* {currentUser.access_level === 2 &&
    <SidebarMenuItemWithSub
            to=''
            icon='barcode'
            title={"Cards"}
            fontIcon='bi-app-indicator'
    >
            <SidebarMenuItem
                to='/addCard'
                icon='card'
                title={"Add Card"}
                fontIcon='bi-app-indicator'
            />
        <SidebarMenuItem
            to='/listCardNA'
            icon='user'
            title={"List Card Not Assigned"}
            fontIcon='bi-app-indicator'
        />

        <SidebarMenuItem
            to='/listCard'
            icon='user'
            title={"List Card Assigned"}
            fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
            to='/listCardActive'
            icon='card'
            title={"List Cards Active"}
            fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
            to='/listCardFBS'
            icon='card'
            title={"List Cards FB"}
            fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
            to='/listCardROT'
            icon='card'
            title={"List Cards Review+"}
            fontIcon='bi-app-indicator'
        />
        <SidebarMenuItem
            to='/listCardOneTime'
            icon='card'
            title={"List Cards One Time"}
            fontIcon='bi-app-indicator'
        />
        </SidebarMenuItemWithSub>}
        */}
      {/* access level 0 1 2 admin manager operator*/}
      {(currentUser.access_level === 0 ||
        currentUser.access_level === 1 ||
        currentUser.access_level === 2) && (
        <>
          <SidebarMenuItemWithSub to='' icon='user' title={'User'} fontIcon='bi-app-indicator'>
            <SidebarMenuItem
              to='/addUser'
              icon='add-item'
              title={'Add User'}
              fontIcon='bi-app-indicator'
            />
            {/*TODO add pending activation users and have a button where click  the menu link and review links is opened on a new tab and then approve the user*/}
            <SidebarMenuItem
              to='/listUser'
              icon='user'
              title={'List User'}
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem
              to='/listDisabledUser'
              icon='user'
              title={'User Pending Activation'}
              fontIcon='bi-app-indicator'
            />
          </SidebarMenuItemWithSub>
          <SidebarMenuItemWithSub to='' icon='barcode' title={'Card'} fontIcon='bi-app-indicator'>
            <SidebarMenuItem
              to='/addCard'
              icon='add-item'
              title={'Add Card'}
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem
              to='/listCardNotAssigned'
              icon='barcode'
              title={'List Card Not Assigned'}
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem
              to='/listCardAssigned'
              icon='barcode'
              title={'List Card Assigned'}
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem
              to='/listCardActive'
              icon='barcode'
              title={'List Cards Active'}
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem
              to='/listCardFBS'
              icon='barcode'
              title={'List Cards FB'}
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem
              to='/listCardROT'
              icon='barcode'
              title={'List Cards Review+'}
              fontIcon='bi-app-indicator'
            />
            <SidebarMenuItem
              to='/listCardOneTime'
              icon='barcode'
              title={'List Cards One Time'}
              fontIcon='bi-app-indicator'
            />
          </SidebarMenuItemWithSub>
        </>
      )}
      {/*access level 0 1 2 admin manager operator*/}
      {(currentUser.access_level === 0 ||
        currentUser.access_level === 1 ||
        currentUser.access_level === 2) && (
        <SidebarMenuItem
          to='/dailyLogs'
          icon='calendar'
          title={'Daily Logs'}
          fontIcon='bi-app-indicator'
        />
      )}
      {/*access level 0 only admin*/}
      {currentUser.access_level === 0 && (
        <SidebarMenuItemWithSub to='' icon='user' title={'Partner'} fontIcon='bi-app-indicator'>
          <SidebarMenuItem
            to='/addPartner'
            icon='add-item'
            title={'Add Partner'}
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='/listPartner'
            icon='user'
            title={'List Partner'}
            fontIcon='bi-app-indicator'
          />
        </SidebarMenuItemWithSub>
      )}

      {/*access level 0 and 1 only admin manager*/}
      {(currentUser.access_level === 0 || currentUser.access_level === 1) && (
        <SidebarMenuItemWithSub to='' icon='user' title={'Operator'} fontIcon='bi-app-indicator'>
          <SidebarMenuItem
            to='/addOperator'
            icon='add-item'
            title={'Add Operator'}
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='/assignOperator'
            icon='add-item'
            title={'Assign Operator'}
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='/listOperator'
            icon='user'
            title={'List Operator'}
            fontIcon='bi-app-indicator'
          />
        </SidebarMenuItemWithSub>
      )}
      {/*access level 0 and 1 only admin manager*/}
      {(currentUser.access_level === 0 || currentUser.access_level === 1) && (
        <SidebarMenuItemWithSub to='' icon='user' title={'Report'} fontIcon='bi-app-indicator'>
          <SidebarMenuItem
            to='/ViewReport'
            icon='add-item'
            title={'View Report'}
            fontIcon='bi-app-indicator'
          />
          <SidebarMenuItem
            to='/pendingReport'
            icon='add-item'
            title={'Pending Report'}
            fontIcon='bi-app-indicator'
          />
        </SidebarMenuItemWithSub>
      )}
    </>
  )
}

export {SidebarMenuMain}
