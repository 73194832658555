/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {baseRoute} from '../../endpoints/endpoints'
import clsx from "clsx";
import {Link, useNavigate} from "react-router-dom";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable  from 'react-bootstrap-table-next';
import ToolkitProvider, { Search,CSVExport  } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;


const ListPartner: FC = () => {
    const config = {
        header: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
    };
    const navigate = useNavigate();


    const [{ data: putData, loading: putLoading, error: putError },refreshData] = baseRoute({
        url: '/a/list_partner',
        method: 'get',
        headers: config.header,
    },{
        manual: false,
    });

    useEffect(() => {
            refreshData().catch((err: any) => console.log(err));
    }, []);

    const intl = useIntl()
    let columns=[{
        dataField: 'username',
        text: 'Username',
        sort: true,
    },{
        dataField: 'company',
        text: 'Company Name',
        sort: true,
    },{
        dataField: 'email',
        text: 'Email',
        sort: true,
    }
    ];
    return (
        <>
            <PageTitle breadcrumbs={[]}>List Of Partner</PageTitle>

            {/* begin::Row */}
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                {/* begin::Col */}
                <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
                    <div className="card card-custom">
                        <div className="card-header">
                            <h3 className="card-title">Partner</h3>
                            <div className="card-toolbar">
                                <Link type="button"  className="btn btn-sm btn-light" to={'/addPartner'}>
                                    Add Partner
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                {putData &&
                                    <ToolkitProvider
                                        keyField='id'
                                        data={putData}
                                        columns={columns}
                                        search
                                        pagination={ paginationFactory() }
                                        exportCSV
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <div className={'d-flex justify-content-end'}>
                                                        <SearchBar { ...props.searchProps } />
                                                        <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                                                    </div>
                                                    <hr />

                                                    <BootstrapTable
                                                        { ...props.baseProps }
                                                    />
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                }

                            </div>
                        </div>
                    </div>
                </div>
                {/* end::Col */}

            </div>
            {/* end::Row */}


        </>
    )
}

export {ListPartner }
