/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {Button} from 'react-bootstrap'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import {useIntl} from 'react-intl'
import {Link, useNavigate} from 'react-router-dom'
import {KTSVG} from '../../../_metronic/helpers'
import {baseRoute} from '../../endpoints/endpoints'

const {SearchBar} = Search
const {ExportCSVButton} = CSVExport

const ListDisabledUser: FC = () => {
  const config = {
    header: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      // authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  }
  const navigate = useNavigate()
  const [userState, setUserState] = useState<{
    u_company: string
    u_onboarded: boolean
    u_id: number
  }>({u_company: '', u_onboarded: false, u_id: 0})
  const [userStateId, setUserStateId] = useState()

  const [{data: putData, loading: putLoading, error: putError}, refreshData] = baseRoute({
    url: '/user/getAllUserDisabled',
    method: 'get',
    headers: config.header,
  })
  const [{data: updatedState, loading: updatedLoading, error: updatedError}, updateUserState] =
    baseRoute(
      {
        url: '/user/updateUserState',
        method: 'post',
        headers: config.header,
      },
      {
        manual: true,
      }
    )

  function updateUser() {
    updateUserState({data: userState}).then((r) => {
      console.log(r)
      refreshData()
    })
  }
  useEffect(() => {
    return () => {
      refreshData().catch((e) => console.log(e))
    }
  }, [])

  function link(cell, row) {
    return (
      <a href={cell} target={'_blank'}>
        Link
      </a>
    )
  }
  function makeBtn(cell, row) {
    return (
      <div className={'d-flex'}>
        <Button
          type='button'
          className='btn btn-primary btn-sm'
          data-bs-toggle='modal'
          data-bs-target='#kt_modal_1'
          onClick={() => {
            setUserStateId(row.u_id)
            setUserState({
              ...userState,
              u_company: row.u_company_name,
              u_onboarded: row.u_onboarded,
              u_id: row.u_id,
            })
          }}
        >
          Enable
        </Button>
        <Link className='btn btn-sm btn-info m-1' to={'/updateUser?uid=' + cell}>
          View
        </Link>
      </div>
    )
  }
  let columns = [
    {
      dataField: 'u_company_name',
      text: 'Company Name',
      sort: true,
    },
    {
      dataField: 'u_menu_link',
      text: 'Menu Link',
      sort: true,
      formatter: link,
    },
    {
      dataField: 'u_review_link',
      text: 'Review Link',
      sort: true,
      formatter: link,
    },
    {
      dataField: 'u_id',
      text: 'Action',
      width: '10%',
      sort: false,
      formatter: makeBtn,
    },
  ]

  const intl = useIntl()
  return (
    <>
      <div className='modal fade' tabIndex={-1} id='kt_modal_1'>
        <div className='modal-dialog'>
          <div className='modal-content'>
            <div className='modal-header'>
              <h5 className='modal-title'>Change User State</h5>
              <div
                className='btn btn-icon btn-sm btn-active-light-primary ms-2'
                data-bs-dismiss='modal'
                aria-label='Close'
              >
                <KTSVG
                  path='/media/icons/duotune/arrows/arr061.svg'
                  className='svg-icon svg-icon-2x'
                />
              </div>
            </div>
            <div className='modal-body'>
              <div>
                <p>
                  Company Name : {userState.u_company === '' && userStateId && userState.u_company}
                </p>
              </div>
              <div>
                <div className='mb-10'>
                  <div className='form-check form-switch form-check-custom form-check-solid'>
                    <input
                      className='form-check-input'
                      type='checkbox'
                      checked={userState.u_onboarded}
                      value={userStateId}
                      id='flexSwitchDefault'
                      onChange={(e) => {
                        console.log(e.target.checked)
                        console.log(e.target.value)
                        setUserState({
                          ...userState,
                          u_onboarded: e.target.checked,
                          u_id: userStateId,
                        })
                      }}
                    />
                    <label className='form-check-label' htmlFor='flexSwitchDefault'>
                      Enable And Activate User
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div className='modal-footer'>
              <button type='button' className='btn btn-light' data-bs-dismiss='modal'>
                Close
              </button>
              <button
                type='button'
                className='btn btn-primary'
                onClick={updateUser}
                data-bs-dismiss='modal'
              >
                Save changes
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title'>Pending Activation</h3>
              <div className='card-toolbar'>
                {/*<button type="button" className="btn btn-sm btn-light">*/}
                {/*  Add User*/}
                {/*</button>*/}
              </div>
            </div>
            <div className='card-body'>
              <div className='table-responsive'>
                {putData && (
                  <ToolkitProvider
                    keyField='id'
                    data={putData}
                    columns={columns}
                    search
                    pagination={paginationFactory()}
                    exportCSV
                  >
                    {(props) => (
                      <div>
                        <div className={'d-flex justify-content-between'}>
                          <SearchBar {...props.searchProps} />
                          <ExportCSVButton {...props.csvProps}>Export CSV!!</ExportCSVButton>
                        </div>
                        <hr />
                        <BootstrapTable {...props.baseProps} />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  )
}

export {ListDisabledUser}
