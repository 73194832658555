/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'

import {useFormik} from "formik";
import {baseRoute} from "../../endpoints/endpoints";
import {useNavigate} from "react-router-dom";
import {ICard, cardInitialValues as initialValues} from "./CardModel";

const AddCardPage: FC = () => {
    const [data, setData] = useState<ICard>(initialValues)
    const updateData = (fieldsToUpdate: Partial<ICard>): any => {
        const updatedData = Object.assign(data, fieldsToUpdate)
        setData(updatedData)
        return updatedData
    }
    const navigate = useNavigate();

    const config = {
        header: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
    };

    const [{ data: putData, loading: putLoading, error: putError },addUserDB] = baseRoute({
        url: '/user/create_onetime_card',
        method: 'post',
        headers: config.header,
    }, { manual: true });

    const [loading, setLoading] = useState(false)
    const formik = useFormik<ICard>({
        initialValues,
        onSubmit: (values) => {
            setLoading(true)
            setTimeout(() => {
                const updatedData = updateData(values)
                console.log(updatedData)
                addUserDB({
                    data: data
                }).then(r => {
                    console.log(putData);
                    navigate('/listCardNotAssigned?card=added');
                }).catch(e =>{
                    console.log(e);
                    alert("Something went wrong");
                })
                setLoading(false)
            }, 1000)
        },
    })

    return(<>
        <PageTitle breadcrumbs={[]}>Add Card</PageTitle>
        {/* begin::Row */}
        <div className='container-fluid'>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                {/* begin::Col */}
                <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
                    <div className='card mb-5 mb-xl-10'>
                        <div
                            className='card-header border-0 cursor-pointer'
                            role='button'
                            data-bs-toggle='collapse'
                            data-bs-target='#kt_account_profile_details'
                            aria-expanded='true'
                            aria-controls='kt_account_profile_details'
                        >
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Card Details</h3>
                            </div>
                        </div>

                        <div id='kt_account_profile_details' className='collapse show'>
                            <form onSubmit={formik.handleSubmit} noValidate className='form'>
                                <div className='card-body border-top p-9'>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Card Unique Number</label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Card Unique Number'
                                                {...formik.getFieldProps('unique_id')}
                                            />
                                            {formik.touched.unique_id && formik.errors.unique_id && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.unique_id}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='card-body border-top p-9'>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Card Name</label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Card Name'
                                                {...formik.getFieldProps('card_name')}
                                            />
                                            {formik.touched.card_name && formik.errors.card_name && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.card_name}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                        {!loading && 'Save Changes'}
                                        {loading && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                                                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                                        )}
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {/* end::Col */}

            </div>
            {/* end::Row */}
        </div>
    </>)
}


export {AddCardPage}
