/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState, useEffect} from 'react'
import {useFormik} from 'formik'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {PasswordMeterComponent} from '../../../../_metronic/assets/ts/components'
import {useAuth} from '../core/Auth'
import {IUser,userDetailsInitValues as initialValues} from '../core/UserModel'
import {baseRoute} from "../../../endpoints/endpoints";
import StepWizard from "react-step-wizard";

export function Registration() {
    const [loading, setLoading] = useState(false)
    const {saveAuth, setCurrentUser} = useAuth()
    const [isFirst,setIsFirst] = useState<boolean>(true)
    const [data, setData] = useState<IUser>(initialValues)
    const [isGoogle , setIsGoogle] = useState<boolean>(true)
    let { unique_id } = useParams();
    console.log(unique_id);
    const updateData = (fieldsToUpdate: Partial<IUser>): any => {
        const updatedData = Object.assign(data, fieldsToUpdate)
        setData(updatedData)
        return updatedData
    }

    const config = {
        header: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
    };
    const [{ data: putData, loading: putLoading, error: putError },addUserDB] = baseRoute({
        url: '/user/register_user',
        method: 'post',
        headers: config.header,
    }, { manual: true });
    const navigate = useNavigate();
    const formik = useFormik({
        initialValues,
        onSubmit: async (values, {setStatus, setSubmitting}) => {
            setLoading(true)
            try {
                values.u_unique_id = unique_id;
                const updatedData = updateData(values)
                console.log(updatedData)
                addUserDB({
                    data: data
                }).then(r => {
                    console.log(putData);
                    navigate('/dashboard?user=added');
                    //TODO add a landing page with the messgae that the user has been added and the admin will activate the account with image should look clean , and you will be notified
                }).catch(e =>{
                    console.log(e);
                    alert("Something went wrong");
                })
                setLoading(false)
            } catch (error) {
                console.error(error)
                saveAuth(undefined)
                setStatus('The registration details is incorrect')
                setSubmitting(false)
                setLoading(false)
            }
        },
    })

    useEffect(() => {
        PasswordMeterComponent.bootstrap()
    }, [])
    const [state, updateState] = useState({
        form: {},
        SW: null,
        demo: true, // uncomment to see more
    });

    const updateForm = (key, value) => {
        const { form } = state;
        form[key] = value;
        updateState({
            ...state,
            form,
        });
    };

    const onStepChange = (stats) => {
        console.log('Current step:', stats);
        console.log('State:', SW);
        stats.isFirstStep= isFirst;
        if(SW.state.activeState !== 0){
            setIsFirst(false);
            stats.isFirstStep = false;
        }
        console.log('Is it first step?',isFirst);
    };

    const setInstance = (SW: any) => updateState({
        ...state,
        SW,
    });

    const { SW, demo } = state;

    let menu_avail = false;
    return (
            <div id='' className=''>
                <div className={'d-flex justify-content-center align-item-center'}>
                    <div>
                    <div className={''}>
                        <img
                        alt='Logo'
                        src={toAbsoluteUrl('/media/logos/addup-logo.svg')}
                        className='h-100px my-5'
                        />
                    </div>
                    <div className='text-center mb-11'>
                        <h1 className='text-dark fw-bolder mb-3'>Sign Up</h1>
                    </div>
                    </div>
                </div>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>

                    <div className='card-body border-top p-9'>
                        <StepWizard
                            isHashEnabled={true}
                            initialStep={0}
                            onStepChange={onStepChange}
                            instance={setInstance}
                        >
                        <div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Card ID</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Unique ID'

                                    {...formik.getFieldProps('u_unique_id')}
                                    value={unique_id}                                />
                                {formik.touched.u_unique_id && formik.errors.u_unique_id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.u_unique_id}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>
                            <div className='col-lg-8'>
                                <div className='row'>
                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                            placeholder='First name'
                                            {...formik.getFieldProps('u_fname')}
                                        />
                                        {formik.touched.u_fname && formik.errors.u_fname && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_fname}</div>
                                            </div>
                                        )}
                                    </div>

                                    <div className='col-lg-6 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Last name'
                                            {...formik.getFieldProps('u_lname')}
                                        />
                                        {formik.touched.u_lname && formik.errors.u_lname && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_lname}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Company / Store</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Company name'
                                    {...formik.getFieldProps('u_company_name')}
                                />
                                {formik.touched.u_company_name && formik.errors.u_company_name && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.u_company_name}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        </div>

                        <div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Email'
                                    {...formik.getFieldProps('u_email')}
                                />
                                {formik.touched.u_email && formik.errors.u_email && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.u_email}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Password</label>

                            <div className='col-lg-8 fv-row'>
                                <input

                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Password'
                                    {...formik.getFieldProps('u_password')}
                                />
                                {formik.touched.u_password && formik.errors.u_password && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.u_password}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        </div>

                        <div>
                        // TODO integrate google places search over here
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Address</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Address'
                                    {...formik.getFieldProps('u_address')}
                                />
                                {formik.touched.u_address && formik.errors.u_address && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.u_address}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>City</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='City'
                                    {...formik.getFieldProps('u_city')}
                                />
                                {formik.touched.u_city && formik.errors.u_city && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.u_city}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label required fw-bold fs-6'>Zip</label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Zip'
                                    {...formik.getFieldProps('u_zip')}
                                />
                                {formik.touched.u_zip && formik.errors.u_zip && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.u_zip}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        </div>
                        <div>
                        <div className='row mb-6'>
                                <label className='col-lg-4 col-form-label required fw-bold fs-6'>Website</label>

                                <div className='col-lg-8 fv-row'>
                                    <input
                                        type='text'
                                        className='form-control form-control-lg form-control-solid'
                                        placeholder='Website'
                                        {...formik.getFieldProps('u_website')}
                                    />
                                    {formik.touched.u_website && formik.errors.u_website && (
                                        <div className='fv-plugins-message-container'>
                                            <div className='fv-help-block'>{formik.errors.u_website}</div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Contact Phone</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='tel'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Contact Phone number'
                                    {...formik.getFieldProps('u_phone')}
                                />
                                {formik.touched.u_phone && formik.errors.u_phone && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.u_phone}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Menu Link</span>
                                //TODO integrate menu link QR required. (route to support page FB !!)
                            </label>

                            {menu_avail || <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Menu Link'
                                    {...formik.getFieldProps('u_menu_link')}
                                />
                                {formik.touched.u_menu_link && formik.errors.u_menu_link && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.u_menu_link}</div>
                                    </div>
                                )}
                            </div>}
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Place Id</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Place Id'
                                    {...formik.getFieldProps('u_place_id')}
                                />
                                {formik.touched.u_place_id && formik.errors.u_place_id && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.u_place_id}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Review Type</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <select id='u_review_type' name={'u_review_type'} className="form-select" aria-label="Review Type" onChange={(e)=>{
                                    if(e.target.value === "1"){
                                        setIsGoogle(true);
                                        (document.getElementById('u_review_link') as HTMLInputElement).value = "https://search.google.com/local/writereview?placeid=";
                                    }else{
                                        setIsGoogle(false);
                                        (document.getElementById('u_review_link') as HTMLInputElement).value = "";
                                    }
                                }}
                                >
                                    <option value="1">Google</option>
                                    <option value="2">Yelp</option>
                                    <option value="3">Tripadvisor</option>
                                </select>

                                {formik.touched.u_review_type && formik.errors.u_review_type && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.u_review_type}</div>
                                    </div>
                                )}
                            </div>

                        </div>
                        <div className='row mb-6'>
                            <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                <span className='required'>Review Link</span>
                            </label>

                            <div className='col-lg-8 fv-row'>
                                <input
                                    type='text'
                                    className='form-control form-control-lg form-control-solid'
                                    placeholder='Review Link'
                                    id={'u_review_link'}
                                    name={'u_review_link'}
                                    disabled={isGoogle}
                                />
                                {formik.touched.u_review_link && formik.errors.u_review_link && (
                                    <div className='fv-plugins-message-container'>
                                        <div className='fv-help-block'>{formik.errors.u_review_link}</div>
                                    </div>
                                )}
                            </div>
                        </div>
                            <div className={'d-flex justify-content-end py-6 px-9'}>
                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                    {!loading && 'Save Changes'}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                                            Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                    </span>
                                    )}
                                </button>
                            </div>
                        </div>


                        </StepWizard>
                    </div>
                    {SW &&
                        <div className='card-footer d-flex justify-content-around py-6 px-9'>
                            {!isFirst && <button type={'button'} className={'btn btn-secondary'} onClick={SW.previousStep}>Previous
                                Step
                            </button>}

                        <button type={'button'} className={'btn btn-secondary'} onClick={SW.nextStep}>Next Step</button>
                    </div>
                    }

                </form>
            </div>


    )
}
