/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'

import {useFormik} from "formik";
import {IUser ,userDetailsInitValues as initialValues} from "./UserModel";
import {baseRoute} from "../../endpoints/endpoints";
import {useNavigate} from "react-router-dom";

const AddUserPage: FC = () => {
    const [data, setData] = useState<IUser>(initialValues)
    const [isGoogle , setIsGoogle] = useState<boolean>(true)
    const updateData = (fieldsToUpdate: Partial<IUser>): any => {
        const updatedData = Object.assign(data, fieldsToUpdate)
        setData(updatedData)
        return updatedData

    }
    const navigate = useNavigate();

    const config = {
        header: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
    };

    const [{ data: putData, loading: putLoading, error: putError },addUserDB] = baseRoute({
        url: '/user/register_user',
        method: 'post',
        headers: config.header,
    }, { manual: true });

    const [loading, setLoading] = useState(false)
    const formik = useFormik<IUser>({
        initialValues,
        onSubmit: (values) => {
            setLoading(true)
            setTimeout(() => {
                const updatedData = updateData(values)
                console.log(updatedData)
                addUserDB({
                    data: data
                }).then(r => {
                    console.log(putData);
                    navigate('/dashboard?user=added');
                }).catch(e =>{
                    console.log(e);
                    alert("Something went wrong");
                })
                setLoading(false)
            }, 1000)
        },
    })

    function selectChangeHandler(event: React.ChangeEvent<HTMLSelectElement>){
        console.log(event.target.value);

    }

    return(<>
        <PageTitle breadcrumbs={[]}>Add User</PageTitle>
        {/* begin::Row */}
        <div className='container-fluid'>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            {/* begin::Col */}
            <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
                <div className='card mb-5 mb-xl-10'>
                    <div
                        className='card-header border-0 cursor-pointer'
                        role='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_account_profile_details'
                        aria-expanded='true'
                        aria-controls='kt_account_profile_details'
                    >
                        <div className='card-title m-0'>
                            <h3 className='fw-bolder m-0'>User Details</h3>
                        </div>
                    </div>

                    <div id='kt_account_profile_details' className='collapse show'>
                        <form onSubmit={formik.handleSubmit} noValidate className='form'>
                            <div className='card-body border-top p-9'>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full Name</label>
                                    <div className='col-lg-8'>
                                        <div className='row'>
                                            <div className='col-lg-6 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                    placeholder='First name'
                                                    {...formik.getFieldProps('u_fname')}
                                                />
                                                {formik.touched.u_fname && formik.errors.u_fname && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.u_fname}</div>
                                                    </div>
                                                )}
                                            </div>

                                            <div className='col-lg-6 fv-row'>
                                                <input
                                                    type='text'
                                                    className='form-control form-control-lg form-control-solid'
                                                    placeholder='Last name'
                                                    {...formik.getFieldProps('u_lname')}
                                                />
                                                {formik.touched.u_lname && formik.errors.u_lname && (
                                                    <div className='fv-plugins-message-container'>
                                                        <div className='fv-help-block'>{formik.errors.u_lname}</div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Company</label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Company name'
                                            {...formik.getFieldProps('u_company_name')}
                                        />
                                        {formik.touched.u_company_name && formik.errors.u_company_name && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_company_name}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Email'
                                            {...formik.getFieldProps('u_email')}
                                        />
                                        {formik.touched.u_email && formik.errors.u_email && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_email}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Password</label>

                                    <div className='col-lg-8 fv-row'>
                                        <input

                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Password'
                                            {...formik.getFieldProps('u_password')}
                                        />
                                        {formik.touched.u_password && formik.errors.u_password && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_password}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Address</label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Address'
                                            {...formik.getFieldProps('u_address')}
                                        />
                                        {formik.touched.u_address && formik.errors.u_address && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_address}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>City</label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='City'
                                            {...formik.getFieldProps('u_city')}
                                        />
                                        {formik.touched.u_city && formik.errors.u_city && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_city}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Zip</label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Zip'
                                            {...formik.getFieldProps('u_zip')}
                                        />
                                        {formik.touched.u_zip && formik.errors.u_zip && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_zip}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Website</label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Website'
                                            {...formik.getFieldProps('u_website')}
                                        />
                                        {formik.touched.u_website && formik.errors.u_website && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_website}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Contact Phone</span>
                                    </label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='tel'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Contact Phone number'
                                            {...formik.getFieldProps('u_phone')}
                                        />
                                        {formik.touched.u_phone && formik.errors.u_phone && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_phone}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Menu Link</span>
                                    </label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Menu Link'
                                            {...formik.getFieldProps('u_menu_link')}
                                        />
                                        {formik.touched.u_menu_link && formik.errors.u_menu_link && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_menu_link}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Place Id</span>
                                    </label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Place Id'
                                            {...formik.getFieldProps('u_place_id')}
                                        />
                                        {formik.touched.u_place_id && formik.errors.u_place_id && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_place_id}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Review Type</span>
                                    </label>

                                    <div className='col-lg-8 fv-row'>
                                        <select id='u_review_type' name={'u_review_type'} className="form-select" aria-label="Review Type" onChange={(e)=>{
                                            if(e.target.value === "1"){
                                                setIsGoogle(true);
                                                (document.getElementById('u_review_link') as HTMLInputElement).value = "https://search.google.com/local/writereview?placeid=";
                                            }else{
                                                setIsGoogle(false);
                                                (document.getElementById('u_review_link') as HTMLInputElement).value = "";
                                            }
                                        }}
                                        >
                                            <option value="1">Google</option>
                                            <option value="2">Yelp</option>
                                            <option value="3">Tripadvisor</option>
                                        </select>

                                        {formik.touched.u_review_type && formik.errors.u_review_type && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_review_type}</div>
                                            </div>
                                        )}
                                    </div>

                                </div>
                                 <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Review Link </span>
                                    </label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Review Link'
                                            id={'u_review_link'}
                                            name={'u_review_link'}
                                            disabled={isGoogle}
                                        />
                                        {formik.touched.u_review_link && formik.errors.u_review_link && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_review_link}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Subscription</span>
                                    </label>
                                    <div className='col-lg-8 fv-row'>
                                        <select className="form-select" aria-label="Subscription"
                                                {...formik.getFieldProps('u_subscription_type')}
                                          >
                                            <option value="1">One-Time</option>
                                            <option value="2">Subscription</option>
                                        </select>
                                        {formik.touched.u_subscription_type && formik.errors.u_subscription_type && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_subscription_type}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                        <span className='required'>Type <span className={'text-muted fs-7'}>(FB or Other)</span></span>

                                    </label>

                                    <div className='col-lg-8 fv-row'>
                                        <select className="form-select" aria-label="User Type"
                                                {...formik.getFieldProps('u_type')}
                                        >
                                            <option value="Other">Other</option>
                                            <option value="FB">FB</option>
                                        </select>
                                        {formik.touched.u_type && formik.errors.u_type && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.u_type}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>

                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                    {!loading && 'Save Changes'}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* end::Col */}

        </div>
        {/* end::Row */}
        </div>
    </>)
}


export {AddUserPage}
