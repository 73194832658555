import {FC, useEffect} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import {useFormik} from 'formik'
import {baseRoute} from '../../endpoints/endpoints'

const AssignOperator: FC = () => {
  const config = {
    header: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      // authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  }

  const [{data: listUserData, loading: listUserLoading, error: listUserError}, refreshUserList] =
    baseRoute({
      url: '/user/getAllUserEnabled',
      method: 'get',
      headers: config.header,
    })

  const [
    {data: listOperatorData, loading: listOperatorLoading, error: listOperatorError},
    refreshOperatorList,
  ] = baseRoute({
    url: '/a/list_operator',
    method: 'get',
    headers: config.header,
  })

  useEffect(() => {
    refreshUserList()
    refreshOperatorList()
  }, [refreshUserList, refreshOperatorList])

  const formik = useFormik({
    initialValues: {
      userId: '',
      operatorId: '',
    },
    validate: (values) => {
      const errors: any = {}
      if (!values.userId) {
        errors.userId = 'User is required'
      }
      if (!values.operatorId) {
        errors.operatorId = 'Operator is required'
      }
      return errors
    },
    onSubmit: (values) => {
      alert(JSON.stringify(values, null, 2))
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>Assign operator to user</PageTitle>
      <div className='container-fluid'>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
            <div className='card mb-5 mb-xl-10'>
              <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
              >
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Review</h3>
                </div>
              </div>
              <form onSubmit={formik.handleSubmit} noValidate className='form'>
                <div className='card-body border-top p-9'>
                  <div className='row mb-6'>
                    {listUserData && (
                      <div className='col-lg-6'>
                        <label htmlFor='userId' className='form-label required'>
                          User to assign
                        </label>
                        <select
                          id='userId'
                          name='userId'
                          className='form-select'
                          placeholder='Select User'
                          {...formik.getFieldProps('userId')}
                        >
                          <option value=''>Select User</option>
                          {listUserData.map((user) => (
                            <option key={user.u_id} value={user.u_id}>
                              {`${user.u_fname} ${user.u_lname}`}
                            </option>
                          ))}
                        </select>
                        {formik.touched.userId && formik.errors.userId && (
                          <div className='invalid-feedback'>{formik.errors.userId}</div>
                        )}
                      </div>
                    )}
                    {listOperatorData && (
                      <div className='col-lg-6'>
                        <label htmlFor='operatorId' className='form-label required'>
                          Operator to assign
                        </label>
                        <select
                          id='operatorId'
                          name='operatorId'
                          className='form-select'
                          placeholder='Select operator'
                          {...formik.getFieldProps('operatorId')}
                        >
                          <option value=''>Select Operator</option>
                          {listOperatorData.map((operator) => (
                            <option key={operator.o_id} value={operator.o_id}>
                              {operator.username}
                            </option>
                          ))}
                        </select>
                        {formik.touched.operatorId && formik.errors.operatorId && (
                          <div className='invalid-feedback'>{formik.errors.operatorId}</div>
                        )}
                      </div>
                    )}
                  </div>
                  <div className='d-flex justify-content-end'>
                    <button type='submit' className='btn btn-primary me-2'>
                      Submit
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export {AssignOperator}
