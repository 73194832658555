/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useState} from 'react'
import Confetti from 'react-confetti'
import GaugeComponent from 'react-gauge-component'
import {useNavigate} from 'react-router-dom'
import {useWindowSize} from 'react-use'
import {PageTitle} from '../../../_metronic/layout/core'

const DashboardWrapper: FC = () => {
  const config = {
    header: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      // authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  }
  const navigate = useNavigate()
  const [run, setRun] = useState(false)
  const {width, height} = useWindowSize()
  setTimeout(() => {
    setRun(false)
    console.log('running')
  }, 5000)

  return (
    <>
      {run && (
        <>
          <div
            style={{
              zIndex: 140,
              background: 'white',
              height: height,
              width: width,
              position: 'fixed',
              top: 0,
              left: 0,
            }}
          >
            <div
              className={
                'text-center d-flex justify-content-center align-items-center vh-100 vw-100 bgi-no-repeat bgi-size-contain bgi-position-x-start bgi-position-y-top'
              }
              style={{backgroundImage: 'url(./media/illustrations/dozzy-1/7.png)'}}
            >
              <div>
                <p className={'display-4 d-block'}>Congratulations!</p>
                <br />
                <p className={'display-6  d-block'}>Your Reviews are now live!</p>
              </div>
            </div>
          </div>
          <Confetti
            style={{zIndex: 150}}
            run={run}
            recycle={run}
            numberOfPieces={500}
            width={width}
            height={height}
          />
        </>
      )}
      <PageTitle>Sentimental Analysis Report</PageTitle>

      <div
        className={'my-3 card bgi-no-repeat bgi-size-auto bgi-position-x-end'}
        style={{backgroundImage: 'url(./media/svg/shapes/abstract-4-dark.svg)'}}
      >
        <div className={'card-body'}>
          <div className={'row'}>
            <div
              className={
                'col-md-4 col-lg-4 col-12 d-flex flex-column align-items-center justify-content-center'
              }
            >
              <span className={'text fs-3 fw-bolder'}>McDonald's</span>

              <span className={'text fs-6'}>1234 Main St, New York, NY 10010</span>
              <select className='my-3 form-select form-select-white' aria-label='Select Month'>
                <option>Select Cards</option>
                <option value='1'>Standee 1</option>
                <option value='2'>FB123456</option>
                <option value='3'>June 2023</option>
              </select>
              <select className='my-3 form-select form-select-white' aria-label='Select Month'>
                <option>Select Month</option>
                <option value='1'>August 2023</option>
                <option value='2'>July 2023</option>
                <option value='3'>June 2023</option>
              </select>
            </div>
            <div
              className={
                'col-md-4 col-12 col-lg-4  justify-content-center align-items-center d-flex'
              }
            >
              <div style={{maxHeight: '250px'}}>
                <GaugeComponent
                  type='semicircle'
                  arc={{
                    colorArray: ['#ff471a', '#f6961e', '#ecdb23', '#aee228', '#6ad72d'],
                    padding: 0.02,
                    subArcs: [{limit: 10}, {limit: 25}, {limit: 50}, {limit: 75}, {limit: 100}],
                  }}
                  pointer={{type: 'arrow', animationDelay: 0}}
                  value={50}
                />
              </div>
            </div>

            <div
              className={
                'col-md-4 col-12 col-lg-4  row justify-content-center align-items-center d-flex text-center'
              }
            >
              <span className={'fs-1 fw-bolder'}>NPS : 50</span>
              <span className={'fs-1'}>
                Collected <span className={'display-6 text-success'}>15</span> reviews in store this
                month.
              </span>
            </div>
          </div>
        </div>
      </div>

      {/* begin::Row */}
      <div className={'my-3 card '}>
        <div className='row card-body g-5 g-xl-10 my-2'>
          <div className={'col-12 row pb-5'}>
            <div className={'col-12  pb-3'}>
              <span className={'fs-3 fw-bolder'}>
                What went right ? <span className={'text-success fs-6'}>(16 Reviews)</span>
              </span>
            </div>
            <div className={'col-12 row row-cols-2 row-cols-lg-5 g-2 g-lg-3'}>
              <div className='col'>
                <div className={'card'}>
                  <div className={'card-body'}>
                    <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                      Food looks great and the taste is amazing . the service is also great and fast
                      . the staff is very friendly and helpful.
                    </a>
                  </div>
                </div>
              </div>
              <div className='col '>
                <div className={'card'}>
                  <div className={'card-body'}>
                    <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                      Food is Great
                    </a>
                  </div>
                </div>
              </div>
              <div className='col '>
                <div className={'card'}>
                  <div className={'card-body'}>
                    <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                      Food is Great
                    </a>
                  </div>
                </div>
              </div>
              <div className='col '>
                <div className={'card'}>
                  <div className={'card-body'}>
                    <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                      Food is Great
                    </a>
                  </div>
                </div>
              </div>
              <div className='col '>
                <div className={'card'}>
                  <div className={'card-body'}>
                    <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                      Food is Great
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={'col-12 row'}>
            <div className={'col-12   pb-3 '}>
              <span className={'fs-3 fw-bolder'}>
                What needs Improvement ? <span className={'text-danger fs-6'}>(16 Reviews)</span>
              </span>
            </div>
            <div className={'col-12 row row-cols-2 row-cols-lg-5 g-2 g-lg-3'}>
              <div className='col'>
                <div className={'card'}>
                  <div className={'card-body'}>
                    <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                      Food is Great
                    </a>
                  </div>
                </div>
              </div>
              <div className='col '>
                <div className={'card'}>
                  <div className={'card-body'}>
                    <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                      Food was not tasty.
                    </a>
                  </div>
                </div>
              </div>
              <div className='col '>
                <div className={'card'}>
                  <div className={'card-body'}>
                    <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                      Food was not tasty.
                    </a>
                  </div>
                </div>
              </div>
              <div className='col '>
                <div className={'card'}>
                  <div className={'card-body'}>
                    <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                      Food was not tasty.
                    </a>
                  </div>
                </div>
              </div>
              <div className='col '>
                <div className={'card'}>
                  <div className={'card-body'}>
                    <a href='#' className='fs-5 text-gray-800 text-hover-primary fw-bold'>
                      Food was not tasty.
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={'card'}>
          <div></div>
        </div>
      </div>

      {/* end::Row */}
    </>
  )
}

export {DashboardWrapper}
