/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useRef} from 'react'
import {PageTitle} from '../../../_metronic/layout/core'
import './ViewReport.css'
import ReactToPrint from 'react-to-print'

const ViewReport: FC = () => {
  const componentRef = useRef()

  return (
    <>
      <PageTitle breadcrumbs={[]}>View Report</PageTitle>
      {/* begin::Row */}
      <div className='container-fluid'>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          {/* begin::Col */}
          <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
            <div className='card mb-5 mb-xl-10'>
              <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
              >
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Operator Details</h3>
                  <ReactToPrint
                    copyStyles={true}
                    documentTitle='Inspection.pdf'
                    trigger={() => <button className='btn btn-dark'>Print this out!</button>}
                    content={() => componentRef.current}
                  ></ReactToPrint>
                </div>
              </div>
            </div>
            <div className='card'>
              <div className='card-body'>
                <div ref={componentRef}>
                  {/* <!-- wrapp1 start  --> */}
                  <div className='wrapp1 shadow wrapp'>
                    {/* <!-- header start  --> */}
                    <header>
                      <div className='container'>
                        <div className='d-flex flex-wrap justify-content-between'>
                          <div className='media align-self-center'>
                            <h2>ADDUP LLC</h2>
                            <div className='media-body'>
                              <p>
                                740 Garrett Green Way, <br />
                                Fort Mill SC 29715
                              </p>
                            </div>
                          </div>
                          <div className='logoImg align-self-center'>
                            <a href=''>
                              <img src='img/logo.svg' alt='img' className='img-fluid' />
                            </a>
                          </div>
                        </div>
                      </div>
                    </header>
                    {/* <!-- header end  --> */}
                    <div className='Wrapp1Inner'>
                      <div className='container'>
                        <h1>
                          Sentimental Analysis
                          <br /> Report December 2023
                        </h1>
                        <p>Prepared for McDonalds 15th Suntech Street Chicago – 30433</p>
                        <p>
                          <strong>Generated on:</strong> 12/12/2023
                        </p>
                      </div>
                    </div>
                    <div className='linkBottom position-absolute'>
                      <a href='https://addup.us/' target='_blank'>
                        https://addup.us/
                      </a>
                    </div>
                  </div>
                  {/* <!-- wrapp1 end --> */}

                  {/* <!-- wrapper2 start  --> */}
                  <div className='wrapper2 wrapp shadow wrapperCommonImg'>
                    {/* <!-- commonHeading start  --> */}
                    <div className='commonHeading'>
                      <div className='container'>
                        <a href=''>
                          <img src='img/logo.svg' alt='img' className='img-fluid' />
                        </a>
                      </div>
                    </div>
                    {/* <!-- conteiner start  --> */}
                    <div className='container'>
                      {/* <!-- item 1 start  --> */}
                      <div className='item1'>
                        <h2>Purpose of the report</h2>
                        <p>
                          Sentiment analysis, an opinion mining method, provides vital business
                          intelligence. This report offers objective insights using NPS, enabling
                          real-time analysis for improved customer service, brand monitoring, market
                          research, and performance tracking. It's a powerful tool for enhancing
                          products and services by understanding and addressing customer sentiments.
                        </p>
                      </div>
                      {/* <!-- item 2 start  --> */}
                      <div className='item2'>
                        <h2>Net Promoters Score</h2>
                        <div className='media'>
                          <img src='img/Chart.png' alt='img' className='img-fluid align-self-end' />
                          <div className='media-body align-self-end'>
                            <p>
                              Net Promoters Score: A metric that measures customer satisfaction and
                              loyalty based on reviews & feedback. (Detractors, Passives and
                              Promoters, Formula is No P – No of D/ No of respondents * 100).
                            </p>
                          </div>
                        </div>
                      </div>
                      {/* <!-- item 3 start  --> */}
                      <div className='item3'>
                        <h2>Customer Sentiments</h2>
                        {/* <!-- row start  --> */}
                        <div className='row'>
                          {/* <!-- single item start  --> */}
                          <div className='col-6'>
                            <div className='itemMid'>
                              <h2 className='d-inline-flex'>
                                Top Positive Sentiments{' '}
                                <img
                                  src='img/positive-dynamic.svg'
                                  alt='img'
                                  className='img-fluid'
                                />
                              </h2>
                              <ul>
                                <li>Good food, great ambience</li>
                                <li>Nice Chef, excellent taste of food</li>
                                <li>Very supportive staff -excellent presentation</li>
                              </ul>
                            </div>
                          </div>
                          {/* <!-- single item start  --> */}
                          <div className='col-6'>
                            <div className='itemMid'>
                              <h2 className='d-inline-flex'>
                                Top Negative Sentiments{' '}
                                <img
                                  src='img/negative-dynamic.svg'
                                  alt='img'
                                  className='img-fluid'
                                />
                              </h2>
                              <ul>
                                <li>Unsupportive Staff</li>
                                <li>Bad looking environment</li>
                                <li>Chicken Manchuria sucks</li>
                              </ul>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className='linkBottom position-absolute'>
                      <a href='https://addup.us/' target='_blank'>
                        https://addup.us/
                      </a>
                    </div>
                  </div>
                  {/* <!-- wrapper2 end  --> */}

                  {/* <!-- wrapper3 start  --> */}
                  <div className='wrapper3 wrapp shadow wrapperCommonImg'>
                    {/* <!-- commonHeading start  --> */}
                    <div className='commonHeading'>
                      <div className='container'>
                        <a href=''>
                          <img src='img/logo.svg' alt='img' className='img-fluid' />
                        </a>
                      </div>
                    </div>
                    {/* <!-- conteiner start  --> */}
                    <div className='container'>
                      {/* <!-- itemTop start  --> */}
                      <div className='itemTop'>
                        <h2>Performance Analysis</h2>
                        <p>
                          A process of evaluating the performance of particular card or cards. It
                          consists of No of reviews collected in particular period
                        </p>
                        <table className='table  table-bordered border-2 border-dark'>
                          <thead>
                            <tr>
                              <th scope='col'>Card Identification Number</th>
                              <th scope='col'>Reviews Collected</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>FB32932</td>
                              <td>400</td>
                            </tr>

                            <tr>
                              <td>FB33939</td>
                              <td>123</td>
                            </tr>

                            <tr>
                              <td>FB34349</td>
                              <td>493</td>
                            </tr>

                            <tr>
                              <td>FB33923</td>
                              <td>695</td>
                            </tr>

                            <tr>
                              <td>
                                <strong>TOTAL REVIEWS COLLECTED</strong>
                              </td>
                              <td>1292</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      {/* <!-- item bottom start  --> */}
                      <div className='itemBottom'>
                        <div className='item1'>
                          <h2>
                            Closest competitor/
                            <br />
                            Product Benchmark:
                          </h2>
                          <p>
                            A business or product whose activities have the potential to reduce or
                            set a standard for another business market share in particular area or
                            segment or by customer’s feedback.) McDonalds is your closest
                            competitor, and their positive reviews are 4.5/5.
                          </p>
                        </div>
                        {/* <!-- item2 start  --> */}
                        <div className='item2'>
                          <div className='row'>
                            <div className='col-6'>
                              <div className='itemleft'>
                                <h3>Recommendations and Improvements</h3>
                                <p>
                                  A business or product whose activities have the potential to
                                  reduce or set a standard for another business market share in
                                  particular area or segment or by customer’s feedback. :) McDonalds
                                  is your closest competitor, and their positive reviews are 4.5/5.
                                </p>
                              </div>
                            </div>

                            <div className='col-6'>
                              <div className='itemleft'>
                                <h3>Impact on Business</h3>
                                <p>
                                  It is a prediction of the consequences of reviews, ratings and
                                  performance analysis. It outlines the impact on overall
                                  sentimental analysis for upcoming period.
                                </p>
                                <a href=''>Moderate Impact</a>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* <!-- item bottom end --> */}
                    </div>
                    <div className='linkBottom position-absolute'>
                      <a href='https://addup.us/' target='_blank'>
                        https://addup.us/
                      </a>
                    </div>
                  </div>
                  {/* <!-- wrapper3 end  --> */}

                  {/* <!-- wrapper3 start  --> */}
                  <div className='wrapper4 wrapp shadow wrapperCommonImg'>
                    {/* <!-- commonHeading start  --> */}
                    <div className='commonHeading pb-0'>
                      <div className='container'>
                        <a href=''>
                          <img src='img/logo.svg' alt='img' className='img-fluid' />
                        </a>
                      </div>
                    </div>
                    {/* <!-- conteiner start  --> */}
                    <div className='container'>
                      <div className='commonItem'>
                        <h2>Glossary & Definitions</h2>
                        {/* <!-- single item start  --> */}
                        <div className='sItem'>
                          <h4>What is Sentimental Analysis?</h4>
                          <p>
                            Sentiment analysis, also known as opinion mining, is a natural language
                            processing (NLP) technique that involves the automated analysis of text
                            to determine the emotional tone, attitude, or sentiment expressed within
                            it. The goal of sentiment analysis is to identify and categorize whether
                            the text conveys positive, negative, or neutral feelings, opinions, or
                            sentiments. This process often involves utilizing various NLP methods,
                            such as machine learning algorithms and linguistic analysis, to
                            interpret the underlying emotions and attitudes present in textual
                            content. Sentiment analysis finds applications in a wide range of
                            fields, including marketing, social media monitoring, customer feedback
                            analysis, and reputation management, as it provides valuable insights
                            into public opinion, trends, and user perceptions.
                          </p>
                        </div>
                        {/* <!-- single item  end  --> */}

                        {/* <!-- single item start  --> */}
                        <div className='sItem'>
                          <h4>What is Net Promoting Score?</h4>
                          <p>
                            The Net Promoter Score (NPS) is a metric used to measure customer
                            loyalty and satisfaction based on their likelihood to recommend a
                            company's products or services to others. It is often used in business
                            and marketing to assess overall customer sentiment and gauge the
                            potential for business growth through word-of-mouth recommendations.
                            Promoters (score 60 – 100): These are highly satisfied and loyal
                            customers who are likely to recommend the company to others.
                          </p>
                          <p>
                            Passives (score 40 - 60): These customers are satisfied but not as
                            enthusiastic as promoters. They may or may not actively recommend the
                            company.
                          </p>
                          <p>
                            Detractors (score 0 - 40): These are unsatisfied customers who are
                            unlikely to recommend the company and may even share negative feedback.
                          </p>
                          <p>
                            The Net Promoter Score is calculated by subtracting the percentage of
                            detractors from the percentage of promoters.
                          </p>
                          <p>
                            NPS provides businesses with insights into customer satisfaction,
                            loyalty trends, and areas that require improvement. It's a widely used
                            metric for understanding customer sentiment and guiding strategic
                            decisions to enhance customer experience.
                          </p>
                        </div>
                        {/* <!-- single item  end  --> */}
                        {/* <!-- single item start  --> */}
                        <div className='sItem'>
                          <h4>What are Top Positive Sentiments?</h4>
                          <p>
                            "Top Positive Sentiments" refers to a compilation of the most favorable
                            and affirmative statements extracted from user reviews or feedback
                            regarding a product, service, or experience.
                          </p>
                        </div>
                        {/* <!-- single item  end  --> */}
                      </div>
                    </div>
                    <div className='linkBottom position-absolute'>
                      <a href='https://addup.us/' target='_blank'>
                        https://addup.us/
                      </a>
                    </div>
                  </div>
                  {/* <!-- wrapper3 end  --> */}

                  {/* <!-- wrapper3 start  --> */}
                  <div className='wrapper4 wrapp shadow wrapperCommonImg'>
                    {/* <!-- commonHeading start  --> */}
                    <div className='commonHeading'>
                      <div className='container'>
                        <a href=''>
                          <img src='img/logo.svg' alt='img' className='img-fluid' />
                        </a>
                      </div>
                    </div>
                    {/* <!-- conteiner start  --> */}
                    <div className='container'>
                      <div className='commonItem'>
                        {/* <!-- single item start  --> */}
                        <div className='sItem'>
                          <p>
                            When reviewing user-generated content, such as online reviews, comments,
                            or testimonials, businesses often identify and curate the most positive
                            and enthusiastic statements that express satisfaction, delight, and
                            approval.
                          </p>
                          <p>
                            These statements are chosen based on their portrayal of exceptional
                            experiences, high levels of satisfaction, and strong endorsement. By
                            highlighting these top positive sentiments, organizations aim to
                            showcase the aspects of their offerings that resonate most positively
                            with customers and potential users, ultimately enhancing their brand
                            image, reputation, and credibility.
                          </p>
                        </div>
                        {/* <!-- single item  end  --> */}

                        {/* <!-- single item start  --> */}
                        <div className='sItem'>
                          <h4>What are Top Negative Sentiments?</h4>
                          <p>
                            "Top Negative Sentiments" refers to a compilation of the most critical
                            and unfavorable statements extracted from user reviews or feedback
                            concerning a product, service, or encounter. When analyzing
                            user-generated content like online reviews, comments, or testimonials,
                            businesses often identify and aggregate the most negative and
                            discontented statements that express dissatisfaction, disappointment, or
                            criticism. These statements are selected based on their depiction of
                            subpar experiences, low levels of satisfaction, and strong disapproval.
                            By addressing these top negative sentiments, organizations aim to
                            identify areas for improvement, rectify issues, and enhance their
                            offerings to better align with customer expectations, ultimately
                            fostering better relationships, improved products, and a more positive
                            reputation.
                          </p>
                        </div>
                        {/* <!-- single item  end  --> */}
                        {/* <!-- single item start  --> */}
                        <div className='sItem'>
                          <h4>What is Performance analysis?</h4>
                          <p>
                            Performance Analysis is the process of studying or evaluating the
                            performance of particular scenario or tool in comparison with objective
                            which was to be achieved. It helps to review the tool performance &
                            contribution towards NPS.
                          </p>
                        </div>
                        {/* <!-- single item  end  --> */}

                        {/* <!-- single item start  --> */}
                        <div className='sItem'>
                          <h4>What is closest competition or Product Benchmark?</h4>
                          <p>
                            Closest Competition is the business in similar line or product in same
                            segment, which particular business is in competing or customers are
                            comprising the reviews and comments. Bench Mark is something that serves
                            as a standard by which others may be measured or judged, which is vary
                            from product to product and business to business & area to area. Both
                            are vital for any business to measure growth and create healthy service
                            and share best practices.
                          </p>
                        </div>
                        {/* <!-- single item  end  --> */}
                      </div>
                    </div>
                    <div className='linkBottom position-absolute'>
                      <a href='https://addup.us/' target='_blank'>
                        https://addup.us/
                      </a>
                    </div>
                  </div>
                  {/* <!-- wrapper3 end  --> */}

                  {/* <!-- wrapper3 start  --> */}
                  <div className='wrapper4 wrapp shadow wrapperCommonImg'>
                    {/* <!-- commonHeading start  --> */}
                    <div className='commonHeading'>
                      <div className='container'>
                        <a href=''>
                          <img src='img/logo.svg' alt='img' className='img-fluid' />
                        </a>
                      </div>
                    </div>
                    {/* <!-- conteiner start  --> */}
                    <div className='container'>
                      <div className='commonItem'>
                        {/* <!-- single item start  --> */}
                        <div className='sItem'>
                          <h4>What are Actionable Points, Recommendations and Improvements?</h4>
                          <p>
                            Actionable points, often referred to as action items or actionable
                            recommendations, are specific and concrete steps or tasks that can be
                            taken to address a particular issue, achieve a goal, or improve a
                            situation. These points provide clear guidance on what needs to be done
                            and serve as a practical roadmap for individuals or teams to follow in
                            order to bring about desired changes or outcomes.
                          </p>
                          <p>Actionable points are characterized by being:</p>
                          <p>
                            <strong>Specific:</strong> They clearly outline what needs to be
                            accomplished. Vague or ambiguous language is avoided.
                          </p>
                          <p>
                            <strong>Measurable:</strong> Ideally, they are quantifiable or
                            qualifiable, allowing for assessment of progress and completion.
                          </p>
                          <p>
                            <strong>Achievable:</strong> They should be feasible and within the
                            capabilities and resources available.
                          </p>
                          <p>
                            <strong>Relevant:</strong> They should directly relate to the larger
                            objective or issue at hand and contribute to its resolution or
                            improvement.
                          </p>
                          <p>
                            <strong>Time-Bound:</strong> They include a timeframe or deadline by
                            which the action should be completed.
                          </p>
                          <p>
                            Actionable points are commonly used in various contexts, including
                            project management, business strategies, personal development plans, and
                            problem-solving. They provide a practical way to turn ideas, feedback,
                            or objectives into tangible steps that can be executed to drive change
                            and accomplish desired outcomes.
                          </p>
                        </div>
                        {/* <!-- single item  end  --> */}

                        {/* <!-- single item start  --> */}
                        <div className='sItem'>
                          <h4>What are Impacts?</h4>
                          <p>
                            A statement or action predicts the consequences of disruptions to a
                            business function & it’s process. It is categorized based on intensity
                            of its severity over Business Sentiment
                          </p>
                          <p>
                            <strong>Mild –</strong> Mild Impacts have a low probability of causing a
                            problem but a high potential reward. These reviews are typically good
                            choices for things that have a low potential for negative consequences
                            but a high potential for positive consequences.
                          </p>
                          <p>
                            <strong>Moderate -</strong> Moderate impacts have a medium probability
                            of causing a problem but a low potential reward. These reviews are good
                            choices for things that have a medium potential for negative
                            consequences but a low potential for positive consequences.
                          </p>
                          <p>
                            <strong>Severe:</strong> Severe Impacts have a high probability of
                            causing a problem but a low potential reward. These reviews are bad
                            choices for things that have a high potential for negative consequences
                            but a low potential for positive consequences
                          </p>
                        </div>
                        {/* <!-- single item  end  --> */}
                      </div>
                    </div>
                    <div className='linkBottom position-absolute'>
                      <a href='https://addup.us/' target='_blank'>
                        https://addup.us/
                      </a>
                    </div>
                  </div>
                  {/* <!-- wrapper3 end  --> */}

                  {/* <!-- wrapper3 start  --> */}
                  <div className='wrapper4 wrapp shadow wrapperCommonImg'>
                    {/* <!-- commonHeading start  --> */}
                    <div className='commonHeading'>
                      <div className='container'>
                        <a href=''>
                          <img src='img/logo.svg' alt='img' className='img-fluid' />
                        </a>
                      </div>
                    </div>
                    {/* <!-- conteiner start  --> */}
                    <div className='container'>
                      <div className='commonItem'>
                        {/* <!-- single item start  --> */}
                        <div className='sItem'>
                          <p>
                            This sentiment analysis report is generated through automated natural
                            language processing techniques and may not accurately capture the full
                            context of individual opinions. The results are based on the analysis of
                            text data and might not reflect personal or nuanced sentiments
                            accurately. The report is provided for informational purposes only and
                            should not be considered as a definitive representation of public
                            sentiment. Users are encouraged to interpret the findings alongside
                            other sources of information and exercise discretion when making
                            business decisions based on the report.
                          </p>
                        </div>
                        {/* <!-- single item  end  --> */}

                        {/* <!-- single item start  --> */}
                        <div className='sItem'>
                          <h2>About AddUp</h2>

                          <p>
                            The company specializing in sentiment analysis reports harnesses
                            advanced natural language processing to distill valuable insights from
                            vast text data. Leveraging cutting-edge technology, they decode public
                            sentiment, delivering actionable data for businesses to enhance
                            products, services, and customer interactions. Their expertise lies in
                            translating complex textual opinions into meaningful trends, empowering
                            companies with real-time insights for informed decision-making. With a
                            commitment to accuracy and objectivity, the company plays a pivotal role
                            in shaping data-driven strategies and fostering better customer
                            relationships.
                          </p>
                        </div>
                        {/* <!-- single item  end  --> */}
                      </div>
                    </div>
                    <div className='linkBottom position-absolute'>
                      <a href='https://addup.us/' target='_blank'>
                        https://addup.us/
                      </a>
                    </div>
                  </div>
                  {/* <!-- wrapper3 end  --> */}
                </div>
              </div>
            </div>
          </div>
          {/* end::Col */}
        </div>
        {/* end::Row */}
      </div>
    </>
  )
}

export {ViewReport}
