/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {baseRoute} from '../../endpoints/endpoints'
import clsx from "clsx";
import {Link, useNavigate} from "react-router-dom";
import DataTable from "react-data-table-component";
import {Button} from "react-bootstrap";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable  from 'react-bootstrap-table-next';
import ToolkitProvider, { Search,CSVExport  } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const ListCardNotAssigned: FC = () => {
    const config = {
        header: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
    };

    const navigate = useNavigate();

    const [{ data: putData, loading: putLoading, error: putError },refreshData] = baseRoute({
        url: '/a/unassignedCards',
        method: 'get',
        headers: config.header,
    });

    useEffect(() => {
        return () => {
            refreshData().catch((err: any) => console.log(err));
        };
    }, []);

    function link(cell, row) {
        return (
            <a href={cell} target={"_blank"}>Link</a>
        );
    }
    function makeBtn(cell, row) {
        return (
            <div>
                <Link className="btn btn-sm btn-info m-1"  to={'/linkCard/'+cell}>Link Card</Link>
                <Link className="btn btn-sm btn-info m-1"  to={'/addReview/'+cell}>Add Review</Link>
                <Link className="btn btn-sm btn-info m-1"  to={'/listReview/'+cell}>List Reviews</Link>
                <Link className="btn btn-sm btn-info m-1"  to={'/updateUser?uid='+cell}>View</Link>
            </div>
        );
    }

    let columns:Array<any>=[
        {
            text: 'Unique Id',
            dataField: 'c_unique_id',
            sort: true,
        },
        {
            text: 'Type',
            dataField: 'c_type',
            sort: true,
        },
        // {
        //     name: 'Status',
        //     selector: 'status',
        //     sortable: true,
        // }
    ];
    // @ts-ignore
    return (
        <>
            <PageTitle breadcrumbs={[]}>List Of Card Not Assigned</PageTitle>

            {/* begin::Row */}
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                {/* begin::Col */}
                <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
                    <div className="card card-custom">
                        <div className="card-header">
                            <h3 className="card-title">Cards Not Assigned</h3>
                            <div className="card-toolbar">

                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                {putData &&
                                    <ToolkitProvider
                                        keyField='id'
                                        data={putData}
                                        columns={columns}
                                        search
                                        pagination={ paginationFactory() }
                                        exportCSV
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <div className={'d-flex justify-content-end'}>
                                                        <SearchBar { ...props.searchProps } />
                                                        <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                                                    </div>
                                                    <hr />

                                                    <BootstrapTable
                                                        { ...props.baseProps }
                                                    />
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                }
                                {/*<table className="table table-striped gy-7 gs-7">
                                    <thead>
                                    <tr className="fw-bold fs-6 text-gray-800 border-bottom-2 border-gray-200">
                                        <th className="min-w-200px">Username</th>
                                        <th className="min-w-100px">Company Name</th>
                                        <th className="min-w-100px">Email</th>
                                        <th className="min-w-100px">Status</th>
                                        <th className="min-w-100px">Action</th>
                                    </tr>
                                    </thead>
                                    <tbody>{ putData && putData.map((item: any) => (
                                        <tr>
                                            <td >{item.username}</td>
                                            <td>{item.company}</td>
                                            <td><a href={"mailto:"+item.email} target={'_blank'}>{item.email}</a></td>
                                            <td>{item.isPending}</td>
                                            <td>
                                                <Link className="btn btn-sm btn-info"  to={'/updateUser?uid='+item.u_unique_id}>View</Link>
                                                <button type={'button'} onClick={} >View</button>
                                            </td>
                                        </tr>
                                    ))
                                    }
                                    </tbody>
                                </table>*/}
                            </div>
                        </div>
                    </div>
                </div>
                {/* end::Col */}

            </div>
            {/* end::Row */}


        </>
    )
}

export {ListCardNotAssigned }
