/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {baseRoute} from '../../endpoints/endpoints'
import clsx from "clsx";
import {Link, useNavigate} from "react-router-dom";
import DataTable from "react-data-table-component";
import {Button} from "react-bootstrap";
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import BootstrapTable  from 'react-bootstrap-table-next';
import ToolkitProvider, { Search,CSVExport  } from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit';
import paginationFactory from 'react-bootstrap-table2-paginator';

const { SearchBar } = Search;
const { ExportCSVButton } = CSVExport;

const ListOperator: FC = () => {
    const config = {
        header: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
        },
    };
    const navigate = useNavigate();

    const [filterText, setFilterText] = React.useState('');
    const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);

    const [{ data: putData, loading: putLoading, error: putError },refreshData] = baseRoute({
        url: '/a/list_operator',
        method: 'get',
        headers: config.header,
    });

    useEffect(() => {
            refreshData().catch((err: any) => console.log(err));
    }, []);

    const FilterComponent = ({ filterText, onFilter, onClear }) => (
        <>
            <input
                id="search"
                type="text"
                className='form-control form-control-lg form-control-solid'
                placeholder="Filter By Name"
                aria-label="Search Input"
                value={filterText}
                onChange={onFilter}
            />
            <Button type="button" onClick={onClear}>
                X
            </Button>
        </>
    );
    const subHeaderComponentMemo = useMemo(() => {
        const handleClear = () => {
            if (filterText) {
                setResetPaginationToggle(!resetPaginationToggle);
                setFilterText('');
            }
        };

        return (
            <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />
        );
    }, [filterText, resetPaginationToggle]);
    let columns:Array<any>=[
        {
            text: 'UserName',
            dataField: 'username',
            sortable: true,
        },
        {
            text: 'Email',
            dataField: 'email',
            sortable: true,
        },
        {
            text: 'Company',
            dataField: 'company',
            sortable: true,
        },
        {
            text:'Action',
            dataField: 'o_id',
            formatter: (cellContent: any, row: any) => {
                return (
                        <Link className="btn btn-sm btn-info"  to={'/updateOperator?uid='+cellContent}>View</Link>
                );
            }
        }
    ];
    // @ts-ignore
    return (
        <>
            <PageTitle breadcrumbs={[]}>List Of Operators</PageTitle>

            {/* begin::Row */}
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                {/* begin::Col */}
                <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
                    <div className="card card-custom">
                        <div className="card-header">
                            <h3 className="card-title">Operators</h3>
                            <div className="card-toolbar">
                                <Link to={'/addOperator'} type="button" className="btn btn-sm btn-light">
                                    Add Operator
                                </Link>
                            </div>
                        </div>
                        <div className="card-body">
                            <div className="table-responsive">
                                {putData &&
                                    <ToolkitProvider
                                        keyField='id'
                                        data={putData}
                                        columns={columns}
                                        search
                                        pagination={ paginationFactory() }
                                        exportCSV
                                    >
                                        {
                                            props => (
                                                <div>
                                                    <div className={'d-flex justify-content-end'}>
                                                        <SearchBar { ...props.searchProps } />
                                                        <ExportCSVButton { ...props.csvProps }>Export CSV!!</ExportCSVButton>
                                                    </div>
                                                    <hr />

                                                    <BootstrapTable
                                                        { ...props.baseProps }
                                                    />
                                                </div>
                                            )
                                        }
                                    </ToolkitProvider>
                                }
                            </div>
                        </div>
                    </div>
                </div>
                {/* end::Col */}

            </div>
            {/* end::Row */}


        </>
    )
}

export {ListOperator }
