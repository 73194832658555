/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'

import {useFormik} from "formik";
import {IOperator, operatorDetailsInitValues as initialValues} from "./OperatorModel";
import {baseRoute} from "../../endpoints/endpoints";
import {useNavigate} from "react-router-dom";

const AddOperatorPage: FC = () => {
    const [data, setData] = useState<IOperator>(initialValues)
    const updateData = (fieldsToUpdate: Partial<IOperator>): any => {
        const updatedData = Object.assign(data, fieldsToUpdate)
        setData(updatedData)
        return updatedData

    }
    const navigate = useNavigate();

    const config = {
        header: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
    };

    const [{ data: putData, loading: putLoading, error: putError },addOperatorDB] = baseRoute({
        url: '/a/register_operator',
        method: 'post',
        headers: config.header,
    }, { manual: true });

    const [loading, setLoading] = useState(false)
    const formik = useFormik<IOperator>({
        initialValues,
        onSubmit: (values) => {
            setLoading(true)
            setTimeout(() => {
                const updatedData = updateData(values)
                console.log(updatedData)
                addOperatorDB({
                    data: data
                }).then(r => {
                    console.log(putData);
                    //TODO: add success toast
                    navigate('/listOperator?user=added');
                }).catch(e =>{
                    console.log(e);
                    alert("Something went wrong");
                })
                setLoading(false)
            }, 1000)
        },
    })

    return(<>
        <PageTitle breadcrumbs={[]}>Add Operator</PageTitle>
        {/* begin::Row */}
        <div className='container-fluid'>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
            {/* begin::Col */}
            <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
                <div className='card mb-5 mb-xl-10'>
                    <div
                        className='card-header border-0 cursor-pointer'
                        role='button'
                        data-bs-toggle='collapse'
                        data-bs-target='#kt_account_profile_details'
                        aria-expanded='true'
                        aria-controls='kt_account_profile_details'
                    >
                        <div className='card-title m-0'>
                            <h3 className='fw-bolder m-0'>Operator Details</h3>
                        </div>
                    </div>

                    <div id='kt_account_profile_details' className='collapse show'>
                        <form onSubmit={formik.handleSubmit} noValidate className='form'>
                            <div className='card-body border-top p-9'>
                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Username</label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Username'
                                            {...formik.getFieldProps('username')}
                                        />
                                        {formik.touched.username && formik.errors.username && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.username}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Email'
                                            {...formik.getFieldProps('email')}
                                        />
                                        {formik.touched.email && formik.errors.email && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.email}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>


                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Password</label>

                                    <div className='col-lg-8 fv-row'>
                                        <input

                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Password'
                                            {...formik.getFieldProps('password')}
                                        />
                                        {formik.touched.password && formik.errors.password && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.password}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>

                                <div className='row mb-6'>
                                    <label className='col-lg-4 col-form-label required fw-bold fs-6'>Company</label>

                                    <div className='col-lg-8 fv-row'>
                                        <input
                                            type='text'
                                            className='form-control form-control-lg form-control-solid'
                                            placeholder='Company name'
                                            {...formik.getFieldProps('company')}
                                        />
                                        {formik.touched.company && formik.errors.company && (
                                            <div className='fv-plugins-message-container'>
                                                <div className='fv-help-block'>{formik.errors.company}</div>
                                            </div>
                                        )}
                                    </div>
                                </div>


                            </div>

                            <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                <button type='submit' className='btn btn-primary' disabled={loading}>
                                    {!loading && 'Save Changes'}
                                    {loading && (
                                        <span className='indicator-progress' style={{display: 'block'}}>
                  Please wait...{' '}
                                            <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
                                    )}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
            {/* end::Col */}

        </div>
        {/* end::Row */}
        </div>
    </>)
}


export {AddOperatorPage}
