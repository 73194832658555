export interface ICard {
  unique_id: string
  card_name: string
  u_id: string
}

export const cardInitialValues: ICard = {
  unique_id: '',
  card_name: '',
  u_id: '',
}
