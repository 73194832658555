import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {getCSSVariableValue} from '../../_metronic/assets/ts/_utils'
import {WithChildren} from '../../_metronic/helpers'
import {AddUserPage} from '../pages/user/AddUser'
import {UpdateUserPage} from '../pages/user/UpdateUser'
import {AddOperatorPage} from '../pages/user/AddOperator'
import {AddPartnerPage} from '../pages/user/AddPartner'
import {ListOperator} from '../pages/user/ListOperator'
import {ListUser} from '../pages/user/ListUser'
import {ListPartner} from '../pages/user/ListPartner'
import {DailyLogsAll} from '../pages/logs/DailyLogsAll'
import {ListCardNotAssigned} from '../pages/card/ListCardsNotAssigned'
import {AddCardPage} from '../pages/card/AddCard'
import {ListCardAssigned} from '../pages/card/ListCardsAssigned'
import {AddReview} from '../pages/user/AddReview'
import {ListCardOneTime} from '../pages/card/ListCardsOneTime'
import {ListCardROT} from '../pages/card/ListCardsROT'
import {ListCardFBS} from '../pages/card/ListCardsFBS'
import {ListCardActive} from '../pages/card/ListCardsActive'
import {ListCardUser} from '../pages/card/ListCardsUser'
import {LinkCard} from '../pages/card/LinkCard'
import {ListDisabledUser} from '../pages/user/ListDisabledUser'
import {UpdateOperatorPage} from '../pages/user/UpdateOperator'
import {ViewReport} from '../pages/report/ViewReport'
import {ReviewForm} from '../pages/report/ReviewForm'
import {AssignOperator} from '../pages/user/AssignOperator'
import {PendingReport} from '../pages/report/PendingReport'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const AccountPage = lazy(() => import('../modules/accounts/AccountPage'))

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='dailyLogs' element={<DailyLogsAll />} />
        <Route path='listUser' element={<ListUser />} />
        <Route path='listDisabledUser' element={<ListDisabledUser />} />
        <Route path='addUser' element={<AddUserPage />} />
        <Route path='addPartner' element={<AddPartnerPage />} />
        <Route path='listPartner' element={<ListPartner />} />
        <Route path='addOperator' element={<AddOperatorPage />} />
        <Route path='listOperator' element={<ListOperator />} />
        <Route path='updateUser' element={<UpdateUserPage />} />
        <Route path='updateOperator' element={<UpdateOperatorPage />} />
        <Route path='addCard' element={<AddCardPage />} />
        <Route path='listCardNotAssigned' element={<ListCardNotAssigned />} />
        <Route path='listCardAssigned' element={<ListCardAssigned />} />
        <Route path='listReview/:u_id' element={<ListCardUser />} />
        <Route path='listCardOneTime' element={<ListCardOneTime />} />
        <Route path='listCardFBS' element={<ListCardFBS />} />
        <Route path='listCardActive' element={<ListCardActive />} />
        <Route path='linkCard/:u_id' element={<LinkCard />} />
        <Route path='addReview/:u_id' element={<AddReview />} />
        <Route path='listCardROT' element={<ListCardROT />} />
        <Route path='viewReport' element={<ViewReport />} />
        <Route path='reviewForm/:u_id' element={<ReviewForm />} />
        <Route path='assignOperator' element={<AssignOperator />} />
        <Route path='pendingReport' element={<PendingReport />} />
        {/* Lazy Modules
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='crafted/account/*'
          element={
            <SuspensedView>
              <AccountPage />
            </SuspensedView>
          }
        />*/}

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
