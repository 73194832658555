/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {useFormik} from 'formik'
import {baseRoute} from '../../endpoints/endpoints'
import {useNavigate} from 'react-router-dom'
import {ICard, cardInitialValues as initialValues} from './CardModel'
import useUserDataStore from '../../store/userStore'

const LinkCard: FC = () => {
  const [data, setData] = useState<ICard>(initialValues)
  const [cardList, setCardList] = useState([])

  const {userData} = useUserDataStore()

  const userId = userData?.u_id
  const uType = userData?.u_type || ''

  const updateData = (fieldsToUpdate: Partial<ICard>): any => {
    const updatedData = Object.assign(data, fieldsToUpdate)
    setData({...updatedData, u_id: userId})
    return updatedData
  }
  const navigate = useNavigate()

  const config = {
    header: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      // authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  }

  //   list unassign card
  const [
    {
      data: unassignedCardListData,
      loading: unassignedCardListLoading,
      error: unassignedCardListError,
    },
    refreshUnassignedCardList,
  ] = baseRoute({
    url: '/a/unassignedCards',
    method: 'get',
    headers: config.header,
  })
  useEffect(() => {
    refreshUnassignedCardList()
  }, [refreshUnassignedCardList])

  useEffect(() => {
    if (unassignedCardListData) {
      if (uType === 'FB') {
        const filteredList = unassignedCardListData.filter(
          (card) => card.c_unique_id.slice(0, 2) === 'FB'
        )
        setCardList(filteredList)
      } else {
        const filteredList = unassignedCardListData.filter(
          (card) => card.c_unique_id.slice(0, 2) !== 'FB'
        )
        setCardList(filteredList)
      }
    }
  }, [unassignedCardListData])

  console.log(cardList)

  // API link card to user
  const [{data: putData, loading: putLoading, error: putError}, addUserDB] = baseRoute(
    {
      url: '/user/linkCard',
      method: 'post',
      headers: config.header,
    },
    {manual: true}
  )

  const [loading, setLoading] = useState(false)
  const formik = useFormik<ICard>({
    initialValues,
    onSubmit: (values) => {
      setLoading(true)
      setTimeout(() => {
        const updatedData = updateData({...values, u_id: userId})
        console.log(updatedData)
        addUserDB({
          data: {...values, u_id: userId},
        })
          .then((r) => {
            console.log(putData)
            navigate('/listCardAssigned?card=added')
          })
          .catch((e) => {
            console.log(e)
            alert('Something went wrong')
          })
        setLoading(false)
      }, 1000)
    },
  })

  return (
    <>
      <PageTitle breadcrumbs={[]}>Link Card</PageTitle>
      {/* begin::Row */}
      <div className='container-fluid'>
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          {/* begin::Col */}
          <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
            <div className='card mb-5 mb-xl-10'>
              <div
                className='card-header border-0 cursor-pointer'
                role='button'
                data-bs-toggle='collapse'
                data-bs-target='#kt_account_profile_details'
                aria-expanded='true'
                aria-controls='kt_account_profile_details'
              >
                <div className='card-title m-0'>
                  <h3 className='fw-bolder m-0'>Card Details</h3>
                </div>
              </div>

              <div id='kt_account_profile_details' className='collapse show'>
                <form onSubmit={formik.handleSubmit} noValidate className='form'>
                  <div className='card-body border-top p-9'>
                    <div className='row mb-6'>
                      <label htmlFor='cardNumber' className='col-lg-4 col-form-label'>
                        Assign Card
                      </label>
                      <div className='col-lg-8'>
                        <select
                          name='cardNumber'
                          id='cardNumber'
                          className='form-select'
                          placeholder='Select Card'
                          {...formik.getFieldProps('unique_id')}
                        >
                          <option value=''>Select Card</option>
                          {cardList.length > 0 ? (
                            cardList.map((card) => (
                              <option value={card.c_unique_id}>{card.c_name}</option>
                            ))
                          ) : (
                            <option value=''>No card found!</option>
                          )}
                        </select>
                      </div>
                    </div>
                  </div>

                  <div className='card-footer d-flex justify-content-end py-6 px-9'>
                    <button type='submit' className='btn btn-primary' disabled={loading}>
                      {!loading && 'Save Changes'}
                      {loading && (
                        <span className='indicator-progress' style={{display: 'block'}}>
                          Please wait...{' '}
                          <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                        </span>
                      )}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
          {/* end::Col */}
        </div>
        {/* end::Row */}
      </div>
    </>
  )
}

export {LinkCard}
