
export interface IUser {
    u_fname: string
    u_lname: string
    u_company_name: string
    u_address: string
    u_city: string
    u_zip: string
    u_website: string
    u_email: string
    u_password: string
    u_menu_link: string
    u_review_type: string
    u_review_link: string
    u_place_id: string
    u_subscription_type: string
    isPending: boolean
    u_type: string
    u_phone: string
    u_country: string

}

export const userDetailsInitValues: IUser = {
    u_fname: '',
    u_lname: '',
    u_company_name: '',
    u_phone: '',
    u_country: '',
    u_address: '',
    u_city: '',
    u_zip: '',
    u_website: '',
    u_email: '',
    u_password: '',
    u_menu_link: '',
    u_subscription_type: '1',
    u_review_type: '1',
    u_review_link: 'https://search.google.com/local/writereview?placeid=',
    u_place_id: '',
    isPending: false,
    u_type: 'Other',
}
