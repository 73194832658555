/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect} from 'react'
import BootstrapTable from 'react-bootstrap-table-next'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import paginationFactory from 'react-bootstrap-table2-paginator'
import ToolkitProvider, {
  CSVExport,
  Search,
} from 'react-bootstrap-table2-toolkit/dist/react-bootstrap-table2-toolkit'
import {Link, useNavigate} from 'react-router-dom'
import {baseRoute} from '../../endpoints/endpoints'
import useUserDataStore from '../../store/userStore'
import {Dropdown, DropdownButton} from 'react-bootstrap'

const {SearchBar} = Search
const {ExportCSVButton} = CSVExport

const ListUser: FC = () => {
  const config = {
    header: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
      // authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  }

  const {setUserData} = useUserDataStore()

  const navigate = useNavigate()

  const [{data: putData, loading: putLoading, error: putError}, refreshData] = baseRoute({
    url: '/user/getAllUserEnabled',
    method: 'get',
    headers: config.header,
  })

  useEffect(() => {
    refreshData()
  }, [])

  function link(cell, row) {
    return (
      <a href={cell} target={'_blank'}>
        Link
      </a>
    )
  }
  function makeBtn(cell, row) {
    return (
      <div className='d-flex justify-content-center align-content-center gap-2'>
        <DropdownButton size='sm' title='Action'>
          <div className='d-flex flex-column'>
            <Link
              className='btn btn-sm btn-info m-1'
              to={'/linkCard/' + cell}
              onClick={() => setUserData(row)}
            >
              Link Card
            </Link>
            <Link className='btn btn-sm btn-info m-1' to={'/reviewForm/' + cell}>
              Add Review
            </Link>
            <Link className='btn btn-sm btn-info m-1' to={'/listReview/' + cell}>
              List Reviews
            </Link>
          </div>
        </DropdownButton>
        <Link className='btn btn-sm btn-info' to={'/updateUser?uid=' + cell}>
          View
        </Link>
      </div>
    )
  }
  let columns = [
    {
      dataField: 'u_company_name',
      text: 'Company Name',
      sort: true,
    },
    {
      dataField: 'u_menu_link',
      text: 'Menu Link',
      sort: true,
      formatter: link,
    },
    {
      dataField: 'u_review_link',
      text: 'Review Link',
      sort: true,
      formatter: link,
    },
    {
      dataField: 'u_id',
      text: 'Action',
      sort: false,
      formatter: makeBtn,
    },
  ]
  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {/* begin::Col */}
        <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
          <div className='card card-custom'>
            <div className='card-header'>
              <h3 className='card-title'>Users</h3>
              <div className='card-toolbar'>
                <button type='button' className='btn btn-sm btn-light'>
                  Add User
                </button>
              </div>
            </div>
            <div className='card-body'>
              <div className='table-responsive'>
                {putData && (
                  <ToolkitProvider
                    keyField='u_id'
                    data={putData}
                    columns={columns}
                    search
                    pagination={paginationFactory()}
                    exportCSV
                  >
                    {(props) => (
                      <div>
                        <div className={'d-flex justify-content-between'}>
                          <SearchBar {...props.searchProps} />
                          <ExportCSVButton {...props.csvProps}>Export CSV!!</ExportCSVButton>
                        </div>
                        <hr />

                        <BootstrapTable {...props.baseProps} />
                      </div>
                    )}
                  </ToolkitProvider>
                )}
              </div>
            </div>
          </div>
        </div>
        {/* end::Col */}
      </div>
      {/* end::Row */}
    </>
  )
}

export {ListUser}
