/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useMemo} from 'react'
import {useIntl} from 'react-intl'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'
import {baseRoute} from '../../endpoints/endpoints'
import clsx from "clsx";
import {Link, useNavigate} from "react-router-dom";
import Table from "../../component/table/table";
const DailyLogsAll: FC = () => {
  const config = {
    header: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      // authorization: "Bearer " + sessionStorage.getItem("accessToken"),
    },
  };
  const navigate = useNavigate();

  const [{ data: putData, loading: putLoading, error: putError },refreshData] = baseRoute({
    url: '/user/getLogs',
    method: 'get',
    headers: config.header,
  });

  useEffect(() => {
    return () => {
      refreshData();
    };
  }, []);

  let columns = useMemo(() => [
        {
          Header: 'Date',
          accessor: 'log_date', // accessor is the "key" in the data
        },
            {
              Header: 'Unique Id',
              accessor: 'u_unique_id',
            },
            {
              Header: 'Menu Visits',
              accessor: 'count_menu',
            },
            {
              Header: 'Review Visits',
              accessor: 'count_review',
            },
            {
              Header: 'Page Visits',
              accessor: 'count_visit',
            },

      ],
      []);
  return (
    <>
      <PageTitle breadcrumbs={[]}>Daily Visit Logs</PageTitle>

        {/* begin::Row */}
        <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
          {/* begin::Col */}
          <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
            <div className="card card-custom">
              <div className="card-header">
                <h3 className="card-title">Visitor Logs</h3>
                <div className="card-toolbar">
                  {/*<button type="button" className="btn btn-sm btn-light">*/}
                  {/*  Add User*/}
                  {/*</button>*/}
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  {putData && <Table columns={columns} data={putData}/>}
                </div>
              </div>
            </div>
          </div>
          {/* end::Col */}

        </div>
        {/* end::Row */}


    </>
  )
}

export {DailyLogsAll}
