import axios from 'axios'
import {makeUseAxios} from 'axios-hooks'

export const LOCAL = 'http://127.0.0.1:5000/api'
export const PROD = 'https://api.addup.site/api'

export const BASE_URL = PROD

export const baseRoute = makeUseAxios({
  axios: axios.create({baseURL: BASE_URL}),
})
