/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {FC, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageTitle} from '../../../_metronic/layout/core'

import {FormikConfig, useFormik} from "formik";
import {IUser ,userDetailsInitValues as initialValues} from "./UserModel";
import {baseRoute} from "../../endpoints/endpoints";
import {useNavigate} from "react-router-dom";
import {Button} from "react-bootstrap";

const UpdateUserPage: FC = () => {
    const [data, setData] = useState<IUser>(initialValues)
    const updateData = (fieldsToUpdate: Partial<IUser>): any => {
        const updatedData = Object.assign(data, fieldsToUpdate)

        setData(updatedData)

        return updatedData
    }
    const url= window.location.href;
    let paramString = url.split('?')[1];
    let queryString = new URLSearchParams(paramString);
    const user = queryString.get('uid')
    const config = {
        header: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
            // authorization: "Bearer " + sessionStorage.getItem("accessToken"),
        },
    };
    const [editing,setEditing] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);

    const [{ data: putData, loading: putLoading, error: putError },addUserDB] = baseRoute({
        url: '/user/getUserByUid',
        method: 'post',
        headers: config.header,
        data:{u_id:user}
    }, { manual: false });

    const [{ data: updatedData, loading: updateLoading, error: updateError },updateUserDB] = baseRoute({
        url: '/user/uu',
        method: 'post',
        headers: config.header,
    }, { manual: true });

    const [{data:deleteData ,loading: deleteLoading,error: deleteError}, deleteUserDB] = baseRoute({
        url: '/user/deleteUser',
        method: 'delete',
        headers: config.header,
        data:{u_id:user}
    }, { manual: true });

    let data_up:IUser;
    if(putData !== undefined)
    {
        data_up = putData;
    }
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false)
    const formik = useFormik<IUser>({
        initialValues,
        onSubmit: (values) => {
            setLoading(true)
            console.log(values)
            setTimeout(() => {
                let updatedData = updateData(values);
                updatedData.u_id = user;
                updateUserDB({data: updatedData}).then(r =>{console.log(r); navigate('/listUser')});
                setLoading(false);
            }, 1000)
        },
    })

    return(<>
        <PageTitle breadcrumbs={[]}>{editing ? "Update User" : "View User" }</PageTitle>
        {/* begin::Row */}
        <div className="modal fade" tabIndex={-1} id="delete_modal">
            <div className="modal-dialog">
                <div className="modal-content">
                    <div className="modal-header">
                        <h5 className="modal-title">Confirm Delete</h5>
                        <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                        >
                            <KTSVG
                                path="/media/icons/duotune/arrows/arr061.svg"
                                className="svg-icon svg-icon-2x"
                            />
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className={'p-3'}>
                        <p className={'fs-5'}>Are You Sure You Want To Delete The User ?</p>
                        </div>
                        <div className={'p-3'}>
                            <p className={'fs-5 fw-bolder text-danger'}>
                                TYPE "DELETE" IN THE BOX BELOW TO CONFIRM
                            </p>
                            <input className={'form-control'} onChange={(e)=>{
                                if(e.target.value==='DELETE') {
                                    setDeleteModal(true)
                                }
                                else{
                                    setDeleteModal(false)
                                }
                            }} />
                        </div>
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            className="btn btn-light"
                            data-bs-dismiss="modal"
                        >
                            Cancel
                        </button>
                        <button type="button" className="btn btn-danger" disabled={!deleteModal} data-bs-dismiss="modal"  onClick={()=>{deleteUserDB().then(()=>navigate('/listUser'))}}>
                            Confirm Delete
                        </button>
                    </div>
                </div>
            </div>
        </div>


        <div className='container-fluid'>
            <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
                {/* begin::Col */}
                <div className='col-md-12 col-lg-12 col-xl-12 col-xxl-12 mb-md-5 mb-xl-10'>
                    <div className='card mb-5 mb-xl-10'>
                        <div
                            className='card-header border-0'
                        >
                            <div className='card-title m-0'>
                                <h3 className='fw-bolder m-0'>Profile Details</h3>
                            </div>
                            <div className="card-toolbar">
                                <div className={'px-3'}>
                                <Button
                                    className='btn btn-danger btn-small'
                                    data-bs-toggle="modal"
                                    data-bs-target="#delete_modal"
                                >
                                    Delete
                                </Button>
                                </div>
                                <div className={'px-3'}>
                                {!editing ? <Button
                                    className='btn btn-primary btn-small'
                                    onClick={() => {
                                        setEditing(true)
                                    }}>
                                    Edit
                                </Button>:<Button
                                    className='btn btn-primary btn-small'
                                    onClick={() => {
                                        setEditing(false)
                                    }}>
                                    Cancel Editing
                                </Button> }
                                </div>
                            </div>
                        </div>

                        {putData && <div id='kt_account_profile_details' className='collapse show'>
                            <form onSubmit={formik.handleSubmit} noValidate className='form'>
                                <div className='card-body border-top p-9'>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Full
                                            Name</label>
                                        <div className='col-lg-8'>
                                            <div className='row'>
                                                <div className='col-lg-6 fv-row'>
                                                    <input
                                                        disabled={!editing}
                                                        type='text'
                                                        className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                                                        placeholder='First name'
                                                        defaultValue={data_up.u_fname}
                                                        id={'u_fname'}
                                                        name={'u_fname'}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {formik.touched.u_fname && formik.errors.u_fname && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.u_fname}</div>
                                                        </div>
                                                    )}
                                                </div>

                                                <div className='col-lg-6 fv-row'>
                                                    <input
                                                        disabled={!editing}
                                                        type='text'
                                                        className='form-control form-control-lg form-control-solid'
                                                        placeholder='Last name'
                                                        defaultValue={data_up.u_lname}
                                                        id={'u_lname'}
                                                        name={'u_lname'}
                                                        onChange={formik.handleChange}
                                                    />
                                                    {formik.touched.u_lname && formik.errors.u_lname && (
                                                        <div className='fv-plugins-message-container'>
                                                            <div className='fv-help-block'>{formik.errors.u_lname}</div>
                                                        </div>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Company</label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                disabled={!editing}
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Company name'
                                                defaultValue={data_up.u_company_name}
                                                id={'u_company_name'}
                                                name={'u_company_name'}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.u_company_name && formik.errors.u_company_name && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.u_company_name}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Email</label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                disabled={!editing}
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Email'
                                                defaultValue={data_up.u_email}
                                                id={'u_email'}
                                                name={'u_email'}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.u_email && formik.errors.u_email && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.u_email}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label
                                            className='col-lg-4 col-form-label required fw-bold fs-6'>Password</label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                disabled={!editing}
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Password'
                                                defaultValue={data_up.u_password}
                                                id={'u_password'}
                                                name={'u_password'}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.u_password && formik.errors.u_password && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.u_password}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Address</label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                disabled={!editing}
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Address'
                                                defaultValue={data_up.u_address}
                                                id={'u_address'}
                                                name={'u_address'}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.u_address && formik.errors.u_address && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.u_address}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>City</label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                disabled={!editing}
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='City'
                                                defaultValue={data_up.u_city}
                                                id={'u_city'}
                                                name={'u_city'}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.u_city && formik.errors.u_city && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.u_city}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Zip</label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                disabled={!editing}
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Zip'
                                                defaultValue={data_up.u_zip}
                                                id={'u_zip'}
                                                name={'u_zip'}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.u_zip && formik.errors.u_zip && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.u_zip}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label required fw-bold fs-6'>Website</label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                disabled={!editing}
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Website'
                                                defaultValue={data_up.u_website}
                                                id={'u_website'}
                                                name={'u_website'}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.u_website && formik.errors.u_website && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.u_website}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>Contact Phone</span>
                                        </label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                disabled={!editing}
                                                type='tel'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Contact Phone number'
                                                defaultValue={data_up.u_phone}
                                                id={'u_phone'}
                                                name={'u_phone'}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.u_phone && formik.errors.u_phone && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.u_phone}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>Menu Link</span>
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                disabled={!editing}
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Menu Link'
                                                defaultValue={data_up.u_menu_link}
                                                id={'u_menu_link'}
                                                name={'u_menu_link'}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.u_menu_link && formik.errors.u_menu_link && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.u_menu_link}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>Place Id</span>
                                        </label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                disabled={!editing}
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Place Id'
                                                defaultValue={data_up.u_place_id}
                                                id={'u_place_id'}
                                                name={'u_place_id'}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.u_place_id && formik.errors.u_place_id && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.u_place_id}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>


                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>Review Type</span>
                                        </label>

                                        <div className='col-lg-8 fv-row'>
                                            <select
                                                disabled={!editing}
                                                id='u_review_type' name={'u_review_type'} className="form-select" aria-label="Review Type" onChange={formik.handleChange}
                                                    defaultValue={data_up.u_review_type}
                                            >
                                                <option value="1">Google</option>
                                                <option value="2">Yelp</option>
                                                <option value="3">Tripadvisor</option>
                                            </select>

                                            {formik.touched.u_review_type && formik.errors.u_review_type && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.u_review_type}</div>
                                                </div>
                                            )}
                                        </div>

                                    </div>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>Review Link </span>
                                        </label>

                                        <div className='col-lg-8 fv-row'>
                                            <input
                                                disabled={!editing}
                                                type='text'
                                                className='form-control form-control-lg form-control-solid'
                                                placeholder='Review Link'
                                                id={'u_review_link'}
                                                name={'u_review_link'}
                                                defaultValue={data_up.u_review_link}
                                                onChange={formik.handleChange}
                                            />
                                            {formik.touched.u_review_link && formik.errors.u_review_link && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.u_review_link}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>Subscription</span>
                                        </label>
                                        <div className='col-lg-8 fv-row'>
                                            <select
                                                disabled={!editing}
                                                className="form-select" aria-label="Subscription"
                                                    {...formik.getFieldProps('u_subscription_type')}
                                            >
                                                <option value="1">One-Time</option>
                                                <option value="2">Subscription</option>
                                            </select>
                                            {formik.touched.u_subscription_type && formik.errors.u_subscription_type && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.u_subscription_type}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className='row mb-6'>
                                        <label className='col-lg-4 col-form-label fw-bold fs-6'>
                                            <span className='required'>Type <span className={'text-muted fs-7'}>(FB or Other)</span></span>

                                        </label>

                                        <div className='col-lg-8 fv-row'>
                                            <select
                                                disabled={!editing}
                                                className="form-select" aria-label="User Type"
                                                    placeholder='Type'
                                                    defaultValue={data_up.u_type}
                                                    id={'u_type'}
                                                    name={'u_type'}
                                                    onChange={formik.handleChange}
                                            >
                                                <option value="Other">Other</option>
                                                <option value="FB">FB</option>
                                            </select>
                                            {formik.touched.u_type && formik.errors.u_type && (
                                                <div className='fv-plugins-message-container'>
                                                    <div className='fv-help-block'>{formik.errors.u_type}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                </div>
                                {editing &&
                                    <div className='card-footer d-flex justify-content-end py-6 px-9'>
                                    <button type='submit' className='btn btn-primary' disabled={loading}>
                                        {!loading && 'Save Changes'}
                                        {loading && (
                                            <span className='indicator-progress' style={{display: 'block'}}>
                                                Please wait...{' '}
                                                <span
                                                    className='spinner-border spinner-border-sm align-middle ms-2'></span>
                                            </span>
                                        )}
                                    </button>
                                </div>
                                }
                            </form>
                        </div>}
                    </div>
                </div>
                {/* end::Col */}

            </div>
        {/* end::Row */}
        </div>
    </>)
}


export {UpdateUserPage}
