/* eslint-disable jsx-a11y/anchor-is-valid */
import {useState} from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import {Link} from 'react-router-dom'
import {useFormik} from 'formik'
import {getUserByToken, login} from '../core/_requests'
import {toAbsoluteUrl} from '../../../../_metronic/helpers'
import {useAuth} from '../core/Auth'

export function NotActivated() {

    return (
            <div className='d-flex flex-column flex-root vh-100 justify-content-center align-items-center'>
                <img src={toAbsoluteUrl('/media/illustrations/dozzy-1/9.png')} alt={"maintenance image"} className={'h-50'}/>
                <p className={'display-5 text-center'}>
                    Your account is not activated yet. You will receive an email once your account is activated.
                </p>
            </div>
    )
}
