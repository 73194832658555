import {create} from 'zustand'

interface UserDataStore {
  userData: any
  setUserData: (newUserData: any) => void
  resetUserData: () => void
}

const useUserDataStore = create<UserDataStore>((set) => ({
  userData: null,

  setUserData: (newUserData) => set({userData: newUserData}),

  resetUserData: () => set({userData: null}),
}))

export default useUserDataStore
